import httpArcheo from "../helpers/http-archeo";

export const getDeposito = async (id) => {
  try {
    const response = await httpArcheo.get(
      `/api/private/v3/archivio/deposito/get/${id}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const saveDeposito = async (deposito) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/archivio/deposito/save",
      deposito
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};

export const deleteDeposito = async (id) => {
  try {
    const response = await httpArcheo.delete(
      `/api/private/v3/archivio/deposito/delete/${id}`
    );
    return response?.data;
  } catch (err) {
    throw err;
  }
};
