import React, { useState, useEffect, useCallback } from "react";
import "../Cassette.scss";
import DataTableSubHeader from "../../../Components/DataTableSubHeader/DataTableSubHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { AddIcon, CrossIcon } from "../../../assets/icons";
import { SubHeaderMobileOption } from "../../../Components/SubHeaderMobileOption/SubHeaderMobileOption";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createCassetta, createRaccolta } from "../../../api";
import { v4 as uuidv4 } from "uuid";
import validateCassetta from "../../../helpers/formTools/validations/cassetta/validateCassetta";
import validateRaccolte from "../../../helpers/formTools/validations/raccolte/validateRaccolte";
import { useAppContext } from "../../../Components/Context/AppContext";
import CassetteRecordDetailForm from "../../../Components/Cassette/AddCassetta/AddCassetteRecordDetailForm/AddCassetteRecordDetailForm";

function AddCassetta() {
  const { comuni, setCassetteFilter, userProfile } = useAppContext();
  const [isMenuActive, setMenuActive] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [issues, setIssues] = useState([]);
  const [showIissues, setShowIssues] = useState(false);
  const [raccolteData, setRaccolteData] = useState([{ id: uuidv4() }]);
  const id = localStorage.getItem("cassetteId");

  //********************************************************** */
  // EFFECTS

  useEffect(() => {
    if (
      !userProfile.roles ||
      !userProfile.roles.some((r) =>
        r.authorities.some((aut) => aut === "ADMIN" || aut === "CASSETTA_WRITE")
      )
    ) {
      toast.error("You are not authorized to access this page");
      navigate("/signin");
    }
  }, [userProfile, navigate]);

  useEffect(() => {
    let issues = validateCassetta(formData);
    issues = issues.concat(validateRaccolte(raccolteData));
    setIssues(issues);
  }, [formData, raccolteData]);

  //************************************************************************** */
  // API CALLS

  const newCassetta = async (data) => {
    try {
      await createCassetta(data);
      toast.success("Data updated successfully!");
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error saving a new "cassetta"`);
      } else {
        toast.error(
          error.response && error.response.detail
            ? error.response.detail
            : `Unknown error saving a new "cassetta"`
        );
        console.error(`Authorization error saving a new "cassetta"`, error);
      }
    }
  };

  const newRaccolta = async (data) => {
    try {
      await createRaccolta(data);
      // toast.success("Data updated successfully!");  // Reducing the number of toasts
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error saving a new "raccolta"`, data);
      } else {
        toast.error(
          error.response && error.response.data && error.response.data.detail
            ? error.response.data.detail
            : `Unknown error saving a new "raccolta"`
        );
        console.error(`Unknown error saving a new "raccolta"`, error);
      }
    }
  };

  // ************************************************
  // ALL HANDLERS

  const handleSave = async () => {
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    navigate(-1);

    const cassetta = {
      ...formData,
      comuneId: comuni.find((comune) => comune.nome === "Sibari").id, // TEMPORARY!!! all repositories are in Sibari
      groups: formData.groups.map((group) => group.name), // Convert groups to array of ids
    };

    const response = await newCassetta(cassetta);
    await Promise.all(
      raccolteData.map(async (raccolta) => {
        raccolta.cassettaId = formData.id;
        await newRaccolta(raccolta);
      })
    );
    setCassetteFilter([]); // Reset the filter
  };

  const handleFormDataChange = (key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const handleRaccoltaChange = (index, key, newValue) => {
    console.log("index", index, "key", key, "newValue", newValue);
    setRaccolteData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, [key]: newValue } : item
      )
    );
  };

  const handleAddRaccolta = () => {
    setRaccolteData((prevData) => [...prevData, { id: uuidv4() }]);
    toast.success("Raccolta aggiunta con successo`!");
  };

  const handleDeleteRaccolta = (raccolta) => {
    setRaccolteData((prevData) =>
      prevData.filter((item) => item.id !== raccolta.id)
    );
    toast.success("Raccolta cancellata con successo`!");
  };

  const disabled = pathname !== "/cassette/edit";
  const cassetteRecordDetailsBreadcrumbs = [
    {
      id: 1,
      label: "Dati Archeoligici",
      iconUrl: true,
    },
    {
      id: 2,
      label: "CASSETTE",
      iconUrl: true,
    },
    { id: 3, label: "Edit CASSETTE Record Details", iconUrl: "" },
  ];

  const subHeaderRightNavOptions = {
    icons: [],
    components: [
      <button onClick={() => navigate(-1)} className="cancel-button">
        <CrossIcon />
        <span className="login-text">Cancel</span>
      </button>,
      <button
        onClick={() => {
          //          navigate(-1);
          handleSave();
        }}
        className="save-button"
      >
        <AddIcon fillColor="#fff" />
        <span className="login-text">Save</span>
      </button>,
    ],
  };

  return (
    <>
      <div className="container">
        <main className="record-detail-main-content">
          <DataTableSubHeader
            title={"CASSETTE Record Details"}
            breadcrumbsData={cassetteRecordDetailsBreadcrumbs}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <CassetteRecordDetailForm
            formData={formData}
            raccolteData={raccolteData}
            handleAddRaccolta={handleAddRaccolta}
            handleDeleteRaccolta={handleDeleteRaccolta}
            handleFormDataChange={handleFormDataChange}
            handleRaccoltaChange={handleRaccoltaChange}
            disabled={disabled}
            issues={issues}
            showIssues={showIissues}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            buttons={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </div>
    </>
  );
}

export default AddCassetta;
