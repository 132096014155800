import React, { useState, useEffect } from "react";
import MarkdownViewer from "../../Components/UserManual/MarkdownViewer.jsx";
import TableOfContents from "../../Components/UserManual/TableOfContents.jsx";
import "./UserManual.scss";
import {
  ArchiveIcon,
  HourglassIcon,
  LoginIcon,
  ManualIcon,
  MapIcon,
  MovingIcon,
  ProfileIcon,
  SearchIcon,
} from "../../assets/icons/index.js";
import DataTableSubHeader from "../../Components/DataTableSubHeader/DataTableSubHeader.jsx";
import { useAppContext } from "../../Components/Context/AppContext.jsx";

const UserManual = () => {
  const { userProfile } = useAppContext();
  const [manual, setManual] = useState([]);
  const [currentChapter, setCurrentChapter] = useState(1);
  const [currentSubchapter, setCurrentSubchapter] = useState(null);
  const [content, setContent] = useState("");
  const [tocVisible, setTocVisible] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);

  const chapters = [
    {
      id: 1,
      title: "Introduzione",
      url: "archeo",
      icon: HourglassIcon,
      authorities: [],
    },
    {
      id: 2,
      title: "PrimoAccesso",
      url: "primoAccesso",
      icon: LoginIcon,
      authorities: [],
    },
    {
      id: 3,
      title: "Consultazione dati archeologici",
      url: "",
      icon: SearchIcon,
      authorities: [],
      subchapters: [
        {
          id: 3.1,
          title: "Ricerca per reperto",
          url: "ricercaReperto",
          authorities: [],
        },
        {
          id: 3.2,
          title: "Ricerca per cassetta",
          url: "ricercaCassetta",
          authorities: [],
        },
        {
          id: 3.3,
          title: "Ricerca per provenienza su WebGis",
          url: "ricercaWebgis",
          authorities: [],
        },
        {
          id: 3.4,
          title: "Ricerca in archivio storico",
          url: "ricercaArchiviostorico",
          authorities: [],
        },
        {
          id: 3.5,
          title: "Consultazione schede ICCD",
          url: "consultazioneSchedeICCD",
          authorities: [],
        },
      ],
    },
    {
      id: 4,
      title: "Aggiornamento dei dati archeologici",
      url: "",
      icon: ArchiveIcon,
      authorities: [
        "ADMIN",
        "CASSETTA_WRITE",
        "REPERTO_WRITE",
        "PUBBLICAZIONE_WRITE",
      ],
      subchapters: [
        {
          id: 4.1,
          title: "Autorizzazioni necessarie",
          url: "autorizzazioniNecessarie",
          authorities: [
            "ADMIN",
            "CASSETTA_WRITE",
            "REPERTO_WRITE",
            "PUBBLICAZIONE_WRITE",
          ],
        },
        {
          id: 4.2,
          title: "Aggiornamento dati dei reperti",
          url: "aggiornamentoDatiReperti",
          authorities: ["ADMIN", "REPERTO_WRITE"],
        },
        {
          id: 4.3,
          title: "Caricamento massivo dei reperti",
          url: "caricamentoMassivoReperti",
          authorities: ["ADMIN", "REPERTO_WRITE"],
        },
        {
          id: 4.4,
          title: "Caricamento massivo delle cassette",
          url: "caricamentoMassivoCassette",
          authorities: ["ADMIN", "CASSETTA_WRITE"],
        },
        {
          id: 4.5,
          title: "Aggiornamento dati di bibliografia",
          url: "aggiornamentoDatiBibliografia",
          authorities: ["ADMIN", "REPERTO_WRITE", "PUBBLICAZIONE_WRITE"],
        },
        {
          id: 4.6,
          title: "Aggiornamento schede ICCD",
          url: "aggiornamentoSchedeICCD",
          authorities: ["ADMIN", "REPERTO_WRITE"],
        },
        {
          id: 4.7,
          title: "Aggiornamento dati in archivio storico",
          url: "aggiornamentoDatiArchivioStorico",
          authorities: ["ADMIN", "ARCHUVIOSTORICO_WRITE"],
        },
      ],
    },
    {
      id: 5,
      title: "Aggiornamento dei contenuti GIS",
      url: "",
      icon: MapIcon,
      authorities: ["ADMIN", "LAYER_WRITE"],
      subchapters: [
        {
          id: 5.1,
          title: "Caricamento di un layer",
          url: "caricamentoLayerTramiteShapefile",
          authorities: ["ADMIN", "LAYER_WRITE"],
        },
        {
          id: 5.2,
          title: "Elimina layer",
          url: "eliminaLayer",
          authorities: ["ADMIN", "LAYER_DELETE"],
        },
      ],
    },
    //{ id: 6, title: "Movimentazione dei reperti", url: "", icon: MovingIcon, authorities: ["ADMIN", "MOVIMENTAZIONE_WRITE"],
    // subchapters:  [
    // ],},
    {
      id: 6,
      title: "Funzionalità di amministrazione",
      url: "",
      icon: ProfileIcon,
      authorities: ["ADMIN", "USER_WRITE", "GROUP_WRITE", "ROLE_WRTE"],
      subchapters: [
        {
          id: 6.1,
          title: "Gestione utenti",
          url: "gestioneUtenti",
          authorities: ["ADMIN", "USER_WRITE"],
        },
        {
          id: 6.2,
          title: "Gestione ruoli",
          url: "gestioneRuoli",
          authorities: ["ADMIN", "ROLE_WRITE"],
        },
        {
          id: 6.3,
          title: "Gestione gruppi",
          url: "gestioneGruppi",
          authorities: ["ADMIN", "GROUP_WRITE"],
        },
      ],
    },
  ];
  /* ************************************************************ */
  // ALL API CALLS

  /* ********************************************************* */
  // ALL EFFECTS

  useEffect(() => {
    // Fetch the Markdown content from a file or API
    const fetchContent = async () => {
      const updatedContent = await Promise.all(
        chapters.map(async (chapter) => {
          let c = {
            id: chapter.id,
            title: chapter.title,
            icon: chapter.icon,
            content: null,
            visible: true,
          };
          const documento = chapter.url
            ? require(`../../Components/UserManual/manualPages/${chapter.url}.md`)
            : null;
          if (
            chapter.authorities.length > 0 &&
            userProfile?.roles &&
            !chapter.authorities.some((a) =>
              userProfile?.roles.some((r) => r.authorities.includes(a))
            )
          )
            c.visible = false;
          if (documento && c.visible === true)
            c.content = await fetch(documento).then((res) => res.text());
          if (chapter.subchapters) {
            c.subchapters = await Promise.all(
              chapter.subchapters.map(async (subchapter) => {
                let sc = {
                  id: subchapter.id,
                  title: subchapter.title,
                  content: null,
                  visible: true,
                };
                if (
                  subchapter.authorities.length > 0 &&
                  userProfile?.authorities &&
                  !subchapter.authorities.some((r) =>
                    userProfile?.authorities.includes(r)
                  )
                )
                  c.visible = false;
                const documento = subchapter.url
                  ? require(`../../Components/UserManual/manualPages/${subchapter.url}.md`)
                  : null;
                if (documento && c.visible === true)
                  sc.content = await fetch(documento).then((res) => res.text());
                return sc;
              })
            );
          }
          return c;
        })
      );
      setManual(updatedContent);
    };
    fetchContent();
  }, []);

  // SET MANUAL PAGE
  useEffect(() => {
    let content = null;
    if (manual.length > 0 && currentChapter) {
      if (currentSubchapter) {
        content = manual
          .find((chapter) => chapter.id === currentChapter)
          .subchapters.find(
            (subchapter) => subchapter.id === currentSubchapter
          ).content;
      } else {
        content = manual.find(
          (chapter) => chapter.id === currentChapter
        ).content;
      }
    }
    if (content !== null) {
      setContent(content);
    }
  }, [currentChapter, currentSubchapter, manual]);

  /* ********************************************************** */
  // NAVIGATION
  const breadcrumbsData = [
    { id: 1, label: "Guida utente", iconUrl: true },
    { id: 2, label: "" },
  ];

  /* ********************************************************** */

  /* ********************************************************** */
  // HHANDLE INTERNAL PAGE CHANGE

  const handlePageChange = (page) => {
    let chapter = null;
    let subchapter = null;

    chapters.forEach((c) => {
      if (c.url === page) {
        chapter = c.id;
        subchapter = c.subchapters ? c.subchapters[0].id : null;
        return;
      } else if (c.subchapters)
        c.subchapters.forEach((sc) => {
          if (sc.url === page) {
            chapter = c.id;
            subchapter = sc.id;
            return;
          }
        });
    });
    if (chapter && subchapter) {
      setCurrentChapter(chapter);
      setCurrentSubchapter(subchapter);
    }
  };

  /* ********************************************************** */

  console.log("Chapter", currentChapter, currentSubchapter);

  return (
    <>
      <DataTableSubHeader
        title={"Guida Utente"}
        breadcrumbsData={breadcrumbsData}
        subHeaderRightNavOptions={{}}
        isMenuActive={isMenuActive}
        setMenuActive={() => setMenuActive(!isMenuActive)}
      />
      <div className="manual-container">
        {tocVisible === false && (
          <div className="table-of-contents-compressed">
            <button
              onClick={() => {
                setTocVisible(true);
              }}
              className="toc-button"
            >
              <ManualIcon width="50px" height="50px" fillColor="#e15615" />
            </button>
          </div>
        )}
        {tocVisible === true && (
          <div className="table-of-contents-compressed">
            <TableOfContents
              manual={manual}
              currentChapter={currentChapter}
              currentSubchapter={currentSubchapter}
              setCurrentChapter={setCurrentChapter}
              setCurrentSubchapter={setCurrentSubchapter}
            />
          </div>
        )}
        <div className="table-of-contents-uncompressed">
          <TableOfContents
            manual={manual}
            currentChapter={currentChapter}
            currentSubchapter={currentSubchapter}
            setCurrentChapter={setCurrentChapter}
            setCurrentSubchapter={setCurrentSubchapter}
          />
        </div>
        <div className="chapter-content" onClick={() => setTocVisible(false)}>
          <MarkdownViewer
            content={content}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};

export default UserManual;
