import { useCallback, useState } from "react";
import "./AddDepositoModal.scss";
import CustomModal from "../../../Common/CustomModal";
import FormField from "../../../FormField/FormField";
import PianiSection from "./PianiSection";
import { toast } from "react-toastify";
import { saveDeposito } from "../../../../api";

const AddDepositoModal = ({ show, setShow }) => {
  const [piani, setPiani] = useState([]);
  const [deposito, setDeposito] = useState({});

  const handleChangeDeposito = useCallback((val, name) => {
    setDeposito((prev) => ({ ...prev, [name]: val }));
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const handleAddPiano = useCallback(() => {
    setPiani((prev) => [
      ...prev,
      { nome: "", id: new Date().getTime(), allegato: null, scaffali: [] },
    ]);
  }, []);

  const handleRemovePiano = useCallback((id) => {
    setPiani((prev) => prev.filter((piano) => piano.id !== id));
  }, []);

  const handleUpdatePiano = useCallback((val, name, id) => {
    setPiani((prev) =>
      prev.map((piano) => {
        if (piano.id === id) {
          return { ...piano, [name]: val };
        }
        return piano;
      })
    );
  }, []);

  const handleAllegatoChange = useCallback((val, id) => {
    console.log("allegato--->>>", val, id);
    setPiani((prev) =>
      prev.map((piano) => {
        if (piano.id === id) {
          return { ...piano, allegato: val };
        }
        return piano;
      })
    );
  }, []);

  const handleUpdateScaffaleNome = useCallback((val, id, pianoId) => {
    setPiani((prev) =>
      prev.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: piano.scaffali.map((scaffale) => {
              if (scaffale.id === id) {
                return { ...scaffale, nome: val };
              }
              return scaffale;
            }),
          };
        }
        return piano;
      })
    );
  }, []);

  const handleScaffaleAllegatoChange = useCallback((val, id, pianoId) => {
    setPiani((prev) =>
      prev.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: piano.scaffali.map((scaffale) => {
              if (scaffale.id === id) {
                return { ...scaffale, allegato: val };
              }
              return scaffale;
            }),
          };
        }
        return piano;
      })
    );
  }, []);

  const handleAddScaffle = useCallback((pianoId) => {
    setPiani((prev) =>
      prev.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: [
              ...piano.scaffali,
              { nome: "", id: new Date().getTime(), allegato: null },
            ],
          };
        }
        return piano;
      })
    );
  }, []);

  const handleRemoveScaffale = useCallback((id, pianoId) => {
    setPiani((prev) =>
      prev.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: piano.scaffali.filter((scaffale) => scaffale.id !== id),
          };
        }
        return piano;
      })
    );
  }, []);

  const handleSubmitDeposito = useCallback(async () => {
    try {
      const formData = {
        ...deposito,
        piani: piani.map((piano) => ({
          nome: piano.nome,
          allegatoId: piano.allegato?.id,
          scaffali: piano.scaffali.map((scaffale) => ({
            nome: scaffale.nome,
            allegatoId: scaffale.allegato?.id,
          })),
        })),
      };
      console.log("piani--->>>", formData);
      await saveDeposito(formData);
      toast.success("Deposito saved successfully");
    } catch (err) {
      console.log(err);
      toast.error("Error while saving deposito");
    } finally {
      handleClose();
    }
  }, [piani, deposito, handleClose]);

  return (
    <CustomModal title={"Add Deposito"} isVisible={show} onClose={handleClose}>
      <div className="add-deposito-modal-body">
        <FormField
          fieldStructure={{
            label: "Identificativo",
            type: "text",
            name: "id",
            editable: true,
          }}
          value={deposito?.id}
          onChange={(val) => handleChangeDeposito(val, "id")}
        />
        <FormField
          fieldStructure={{
            label: "Indirizzo",
            type: "text",
            name: "address",
            editable: true,
          }}
          value={deposito?.indirizzo}
          onChange={(val) => handleChangeDeposito(val, "indirizzo")}
        />
        <PianiSection
          piani={piani}
          onChange={handleUpdatePiano}
          handleAllegatoChange={handleAllegatoChange}
          handleAddPiano={handleAddPiano}
          handleAddScaffle={handleAddScaffle}
          handleRemoveScaffale={handleRemoveScaffale}
          handleUpdateScaffaleNome={handleUpdateScaffaleNome}
          handleRemovePiano={handleRemovePiano}
          handleScaffaleAllegatoChange={handleScaffaleAllegatoChange}
        />
      </div>
      <footer className="modal-footer">
        <button className="modal-footer-button" onClick={handleSubmitDeposito}>
          Add
        </button>
      </footer>
    </CustomModal>
  );
};

export default AddDepositoModal;
