export const validateMovimentazione = (data) => {
  const issues = [];

  if (!data.hasOwnProperty("inizio") || (data.hasOwnProperty("inizio") && !data.inizio)) {
    issues.push("Inizio is required");
  }

  if (!data.hasOwnProperty("termine") || (data.hasOwnProperty("termine") && !data.termine)) {
    issues.push("Termine is required");
  }

  if (!data.hasOwnProperty("tipo") || (data.hasOwnProperty("tipo") && !data.tipo)) {
    issues.push("Tipo is required");
  }

  if (!data.hasOwnProperty("destinazione") || (data.hasOwnProperty("destinazione") && !data.destinazione)) {
    issues.push("Destinazione is required");
  }

  if (!data.hasOwnProperty("autorizzazioneId") || (data.hasOwnProperty("autorizzazioneId") && !data.inizio)) {
    issues.push("Autorizzazione ID is required");
  }

  console.log("Issues: ", data);

  if (!data.hasOwnProperty("referenti") || !Array.isArray(data.referenti) || data.referenti.length === 0) {
    issues.push("Referenti is required and should be a non-empty array");
  } else {
    data.referenti.forEach((referente, index) => {
      if (!referente.hasOwnProperty("tipo") || !referente.tipo) {
        issues.push(`Referente at index ${index} is missing 'tipo'`);
      }
      if (!referente.hasOwnProperty("valore") || !referente.valore) {
        issues.push(`Referente at index ${index} is missing 'valore'`);
      }
    });
  }

  return issues;
};
