import { useState, useCallback, useEffect } from "react";
import { getMuseo } from "../../../../api";
import CustomModal from "../../../Common/CustomModal";
import axios from "axios";

const EditEsposizioneModal = ({ isOpen, onClose }) => {
  const fetchMuseo = useCallback(async () => {
    try {
      const data = await getMuseo();
      console.log("museo data", data);
    } catch (error) {
      console.error("Error fetching museo data", error);
    }
  }, []);

  const fetchPublicMuseo = useCallback(async () => {
    try {
      const response = await axios.get(
        "https://api.museiitaliani.it/cms-ms/api/public/cached/museum",
        {
          params: {
            pageSize: 30,
            page: 3,
          },
        }
      );
      console.log("public museo data", response.data);
    } catch (error) {
      console.error("Error fetching public museo data", error);
    }
  }, []);

  useEffect(() => {
    fetchMuseo();
  }, [fetchMuseo]);

  useEffect(() => {
    fetchPublicMuseo();
  }, [fetchPublicMuseo]);
  return (
    <CustomModal
      title={"Modifica Esposizione"}
      isVisible={isOpen}
      onClose={onClose}
    >
      <div></div>
      <footer className="modal-footer">
        <button className="modal-footer-button">Edit</button>
      </footer>
    </CustomModal>
  );
};

export default EditEsposizioneModal;
