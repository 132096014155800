import * as React from "react";
import "./FilterModal.scss";
import { Select } from "../index";
import { MinusIcon } from "../../assets/icons/minus";
import { CrossIcon, FilterIcon } from "../../assets/icons";
import { RefreshIcons } from "../../assets/icons/refreshIcon";

const FilterButton = ({ text, isReset, onClick, iconSrc, className }) => (
  <button
    className={`btn ${isReset ? "btn-reset" : "btn-apply"}`}
    onClick={onClick}
  >
    {iconSrc}
    {text}
  </button>
);

const FilterOption = ({
  title,
  inputPlaceholder,
  filterValues = [],
  setSelectValue = () => {},
  filter = {},
  sendData,
}) => {
  const [options, setOptions] = React.useState([]);
  const [additionalFilters, setAdditionalFilters] = React.useState([
    { id: "filter-0", label: "", value: "" },
  ]);

  React.useEffect(() => {
    sendData(additionalFilters);
  }, [additionalFilters, sendData]);

  React.useEffect(() => {
    const filteredValues = filterValues.filter(
      (value) => value?.key !== "actions"
    );
    const newOptions = filteredValues.map(({ label }) => ({ value: label }));
    setOptions(newOptions);
  }, [filterValues]);

  const handleAddFilter = () => {
    setAdditionalFilters((prevFilters) => [
      ...prevFilters,
      { id: `filter-${prevFilters.length}`, label: "", value: "" },
    ]);
  };

  const handleRemoveFilter = (index) => {
    setAdditionalFilters((prevFilters) => {
      if (prevFilters.length === 1) {
        return prevFilters; // Ensure at least one filter remains
      }
      return prevFilters.filter((_, i) => i !== index);
    });
  };

  const handleSelectChange = (index, value) => {
    setAdditionalFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index].label = value;
      return updatedFilters;
    });
  };

  const handleInputChange = (index, e) => {
    const { value } = e.target;
    setAdditionalFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index].value = value;
      return updatedFilters;
    });
  };

  return (
    <section className="filter-option">
      <p className="filter-title">{title}</p>
      <div className="filter-input-group" style={{ display: "block" }}>
        {additionalFilters.map((filter, index) => (
          <div
            key={filter.id}
            className="additional-filter"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10px",
              gap: "10px",
            }}
          >
            <Select
              options={options}
              overlap
              onSelect={({ value }) => handleSelectChange(index, value)}
              value={filter.label}
            />
            <span>=</span>
            <input
              className="placeholder"
              placeholder={inputPlaceholder}
              onChange={(e) => handleInputChange(index, e)}
              value={filter.value}
            />
            {index > 0 && ( // Only show remove button if there is more than one filter
              <div class="modal-icon" onClick={() => handleRemoveFilter(index)}>
                <MinusIcon />
              </div>
            )}
          </div>
        ))}
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleAddFilter}
        >
          <path
            d="M14.0007 2.33337C7.57232 2.33337 2.33398 7.57171 2.33398 14C2.33398 20.4284 7.57232 25.6667 14.0007 25.6667C20.429 25.6667 25.6673 20.4284 25.6673 14C25.6673 7.57171 20.429 2.33337 14.0007 2.33337ZM18.6673 14.875H14.8757V18.6667C14.8757 19.145 14.479 19.5417 14.0007 19.5417C13.5223 19.5417 13.1257 19.145 13.1257 18.6667V14.875H9.33398C8.85565 14.875 8.45898 14.4784 8.45898 14C8.45898 13.5217 8.85565 13.125 9.33398 13.125H13.1257V9.33337C13.1257 8.85504 13.5223 8.45837 14.0007 8.45837C14.479 8.45837 14.8757 8.85504 14.8757 9.33337V13.125H18.6673C19.1457 13.125 19.5423 13.5217 19.5423 14C19.5423 14.4784 19.1457 14.875 18.6673 14.875Z"
            fill="#E15615"
          />
        </svg>
      </div>
    </section>
  );
};

function FilterModal({
  closeModal,
  filterValues = [],
  setFilterValue = () => {},
  callBackFilter,
  onReset,
}) {
  const [filter, setFilter] = React.useState({});
  const [newData, setNewData] = React.useState("");

  const handleOnApplyFilter = () => {
    callBackFilter(newData);
    setFilterValue(filter);
    closeModal();
  };

  const handleOnReset = () => {
    setFilterValue({});
    setFilter({});
    closeModal();
    onReset(true);
  };

  return (
    <main className="filter-container">
      <header className="filter-header">
        <h1 className="header-title">FILTER BY</h1>
        <div className="modal-icons">
          <div className="modal-icon" onClick={closeModal}>
            <MinusIcon />
          </div>
          <div className="modal-icon" onClick={closeModal}>
            <CrossIcon />
          </div>
        </div>
      </header>
      <FilterOption
        title="Where"
        inputPlaceholder="Enter Value"
        filterValues={filterValues}
        filter={filter}
        setSelectValue={setFilter}
        sendData={setNewData}
      />
      <hr className="modal-divider" />
      <div className="button-group">
        <FilterButton
          iconSrc={<RefreshIcons />}
          text="Reset"
          isReset
          className="filter-form"
          onClick={handleOnReset}
        />
        <FilterButton
          iconSrc={<FilterIcon fillColor="#fff" />}
          className="filter-form"
          text="Apply Filter"
          onClick={handleOnApplyFilter}
        />
      </div>
    </main>
  );
}

export default FilterModal;
