import { FeatureTable } from "./FeatureTable/FeatureTable";
import { OptionCard } from "../optionCard/optionCard";
import "./featureSummary.scss";
import CustomTooltip from "../CustomTooltip";
import {HandIcon} from "../../assets/icons/index";

const FeatureSummary = ({
  nextClick = () => {},
  closeClick = () => {},
  minimizeClick = () => {},
  properties,
}) => {
  const handleOnNextClick = () => {
    nextClick();
  };
  const handleOnCloseClick = () => {
    closeClick();
  };
  const handleOnMinimizeClick = () => {
    minimizeClick();
  };
  const layerName = properties && properties.layerName ? properties.layerName : "";
  return (
    <div className="feature-summary-container">
      <OptionCard
        isArrowVisible={true}
        title={`INFORMAZIONI ${layerName}`}
        className="feature-table-container"
        onArrowClick={handleOnNextClick}
        onCrossClick={handleOnCloseClick}
        onMinimizeClick={handleOnMinimizeClick}
      >
        { properties === null ? (
          <div className="feature-summary-instructions">
            <HandIcon height="50px" width="50px" fillColor="#6E6E6E"/>
             <br/>  <br/>
             Fare click sulla feature di interesse per avere maggiori informazioni
           </div>
          ) : (
          <FeatureTable
            properties={properties}
          />
          )
      } 

      </OptionCard>
    </div>
  );
};

export default FeatureSummary;
