import { CrossIcon, TrashIcon } from "../../assets/icons";
import { LayersCard } from "../LayersCard/LayersCard";
import "./DeleteLayers.scss";
export const DeleteLayers = ({
  crossClickHandler = () => {},
  handleCallBackCheckLayer,
  onDelete = () => {},
  onCancel = () => {},
  layersMenu = [],
}) => {

  return (
    <LayersCard
      title="Delete Layer"
      layersMenu={layersMenu}
      crossClickHandler={crossClickHandler}
      handleCallBackCheckLayer={handleCallBackCheckLayer}
      isCheckbox={true}
      hasEyes={false}
      className="delete-layer-card-wrapper"
    >
      <div className="delete-layers-button-wrapper">
        <button className="action-button cancel-button" onClick={onCancel}>
          <CrossIcon />
          <span>Cancel</span>
        </button>
        <button className="action-button delete-button" onClick={onDelete}>
          <TrashIcon fillColor="white" />
          <span>Delete</span>
        </button>
      </div>
    </LayersCard>
  );
};
