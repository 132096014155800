import { useState } from "react";
import BlockHeader from "../../Common/BlockHeader/BlockHeader";
import CustomTooltip from "../../CustomTooltip";
import { EditIcon } from "../../../assets/icons";
import EditEsposizioneModal from "./EditEsposizioneModal/EditEsposizioneModal";
import "./Esposizione.scss";

const Esposizione = ({ disabled, repertoId, repertoEsposizione }) => {
  const [isEditModalShow, setIsEditModalShow] = useState(false);
  return (
    <div>
      <BlockHeader title="Esposizione">
        <CustomTooltip text={"Aggiungi Movimentazione"}>
          <button
            className="repertori-button"
            onClick={() => {
              setIsEditModalShow(true);
            }}
          >
            <EditIcon fillColor="#e15615" />
          </button>
        </CustomTooltip>
      </BlockHeader>
      <div>Non ci sono esposizione.</div>
      {isEditModalShow && (
        <EditEsposizioneModal
          isOpen={isEditModalShow}
          onClose={() => setIsEditModalShow(false)}
        />
      )}
    </div>
  );
};

export default Esposizione;
