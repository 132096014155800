import { useMemo } from "react";
import { EditIcon, TrashIcon } from "../assets/icons";
import isColorDark from "../helpers/utils/isColorDark";
import { useLocation } from "react-router-dom";

const useTransformData = (
  data,
  isEditing,
  setIsEditModalOpen,
  setIsDeleteModalOpen,
  setSelectedItem,
  config
) => {
  const { pathname } = useLocation();

  const transformedData = useMemo(() => {
    return data.map((item, index) => {
      const transformedItem = {};

      config.fields.forEach((field) => {
        if (field.type === "text") {
          transformedItem[field.name] = {
            value: item[field.name],
            component: <div>{item[field.name]}</div>,
          };
        } else if (field.type === "color") {
          transformedItem[field.name] = {
            value: item[field.name] || "No value",
            component: (
              <div
                className="color-wrapper"
                style={{ backgroundColor: item[field.name] || "#FFF" }}
              ></div>
            ),
          };
        } else if (field.type === "list") {
          transformedItem[field.name] = {
            value:
              item[field.name]?.map((subItem) => subItem).join(", ") ||
              "No value",
            component: (
              <div className="list-wrapper">
                {item[field.name]?.length ? (
                  item[field.name].map((subItem) => (
                    <p
                      key={`${field.name}-${index}-${subItem}`}
                      className="badge"
                      style={{
                        backgroundColor: "#FFF",
                        color: "#000",
                      }}
                    >
                      {subItem || "No value"}
                    </p>
                  ))
                ) : (
                  <p>{item[field.name]?.[0] || "No value"}</p>
                )}
              </div>
            ),
          };
        } else if (field.type === "custom" && field.name === "groups") {
          transformedItem[field.name] = {
            value:
              item.groups?.map((group) => group.name).join(", ") || "No value",
            component: (
              <div className="group-wrapper">
                {item.groups?.length ? (
                  item.groups.map((group) => (
                    <p
                      key={group.name}
                      className={`${group.name ? "badge" : ""}`}
                      style={{
                        backgroundColor: group.color ? group.color : "#ccc",
                        color: isColorDark(group.color) ? "#fff" : "#000",
                      }}
                    >
                      {group.name || "No value"}
                    </p>
                  ))
                ) : (
                  <p
                    className={`${item.groups?.[0]?.name ? "badge" : ""}`}
                    style={{ backgroundColor: item.groups?.[0]?.color }}
                  >
                    {item.groups?.[0]?.name || "No value"}
                  </p>
                )}
              </div>
            ),
          };
        }
      });

      transformedItem.actions = {
        value: [],
        component: isEditing ? (
          <div className="actions-wrapper">
            {pathname.includes("/groups") ? null : (
              <div
                className="action-icon"
                onClick={() => {
                  setSelectedItem(item);
                  setIsEditModalOpen(true);
                }}
              >
                <EditIcon fillColor="#EE4B2B" />
              </div>
            )}
            <div
              className="action-icon"
              onClick={() => {
                setSelectedItem(item);
                setIsDeleteModalOpen(true);
              }}
            >
              <TrashIcon width={24} height={24} />
            </div>
          </div>
        ) : null,
      };

      return transformedItem;
    });
  }, [
    data,
    isEditing,
    setIsEditModalOpen,
    setIsDeleteModalOpen,
    setSelectedItem,
    pathname,
    config,
  ]);

  return transformedData;
};

export default useTransformData;
