import { Route, Routes } from "react-router-dom";
import PrivateRoute from "../Components/PrivateRoute/PrivateRoute";

// HOME PAGE
import HomePage from "../pages/HomePage/HomePage.jsx";

// USER ACCOUNT HANDLING
import ResetPasswordForm from "../pages/ResetPasswordForm/ResetPasswordForm.jsx";
import PasswordChangePage from "../pages/PasswordChangePage";
import LoginSection from "../pages/LoginSection/LoginSection.jsx";

// MAP VIEW
import MapViewPage from "../pages/MapViewPage/MapViewPage.jsx";

// CASSETTE
import Cassette from "../pages/Cassette/Cassette.jsx";
import AddCassetta from "../pages/Cassette/AddCassette/AddCassetta.jsx";
import EditCassetta from "../pages/Cassette/EditCassetta/EditCassetta.jsx";

// REPERTI
import Reperti from "../pages/Reperti/Reperti.jsx";
import AddReperto from "../pages/Reperti/AddReperto/index.jsx";
import EditReperto from "../pages/Reperti/EditReperto/index.jsx";

// PUBBLICAZIONI
import Pubblicazioni from "../pages/Pubblicazioni/index.jsx";
import EditPublicazioni from "../pages/Pubblicazioni/EditPubblicazioni/EditPublicazioni.jsx";
import AddPublicazioni from "../pages/Pubblicazioni/AddPubblicazioni/AddPubblicazioni.jsx";

// ARCHIVIO STORICO
import Archivio from "../pages/Archivio/index.jsx";
import AddArchivio from "../pages/Archivio/AddArchivio/AddArchivio.jsx";
import EditArchivio from "../pages/Archivio/EditArchivio/EditArchivio.jsx";

// USER MANUAL
import UserManual from "../pages/UserManual/index.jsx";

// Movimentazione
import Movimentazione from "../pages/Movimentazione";
import AddMovimentazione from "../pages/Movimentazione/AddMovimentazione/AddMovimentazione.jsx";
import EditMovimentazione from "../pages/Movimentazione/EditMovimentazione/EditMovimentazione.jsx";

import UsersDetailsTable from "../pages/UserDetailsTable/UsersDetailsTable.jsx";
import RolesDetailsTable from "../pages/RolesDetailsTable/RolesDetailsTable.jsx";
import GroupsDetailsTable from "../pages/GroupsDetailsTable/GroupsDetailsTable.jsx";
import { AuthPage } from "../pages/AuthPage/AuthPage.jsx";
import { SignInForm } from "../pages/SignInForm/SignInForm.jsx";

const AppRoutes = () => (
  <Routes>
    {/* Profile Handling */}
    <Route
      path="/resetpassword"
      element={
        <AuthPage>
          <ResetPasswordForm />
        </AuthPage>
      }
    />
    <Route
      path="/"
      element={
        <AuthPage>
          <LoginSection />
        </AuthPage>
      }
    />
    <Route
      path="/passwordchange"
      element={
        <AuthPage>
          <PasswordChangePage />
        </AuthPage>
      }
    />
    <Route
      path="/signin"
      element={
        <AuthPage>
          <SignInForm />
        </AuthPage>
      }
    />
    <Route
      path="/home"
      element={
        <AuthPage>
          <HomePage />
        </AuthPage>
      }
    />

    {/* Map View */}
    <Route path="/MapView" element={<PrivateRoute element={MapViewPage} />} />

    {/* Cassette */}
    <Route path="/cassette" element={<PrivateRoute element={Cassette} />} />
    <Route
      path="/cassette/view"
      element={<PrivateRoute element={EditCassetta} />}
    />
    <Route
      path="/cassette/edit"
      element={<PrivateRoute element={EditCassetta} />}
    />
    <Route
      path="/cassette/add"
      element={<PrivateRoute element={AddCassetta} />}
    />

    {/* Reperti */}
    <Route path="/reperti" element={<PrivateRoute element={Reperti} />} />
    <Route
      path="/reperti/edit"
      element={<PrivateRoute element={EditReperto} />}
    />
    <Route
      path="/reperti/view"
      element={<PrivateRoute element={EditReperto} />}
    />
    <Route
      path="/reperti/add"
      element={<PrivateRoute element={AddReperto} />}
    />

    {/* Pubblicazioni */}
    <Route
      path="/pubblicazioni"
      element={<PrivateRoute element={Pubblicazioni} />}
    />
    <Route
      path="/pubblicazioni/view"
      element={<PrivateRoute element={EditPublicazioni} />}
    />
    <Route
      path="/pubblicazioni/edit"
      element={<PrivateRoute element={EditPublicazioni} />}
    />
    <Route
      path="/pubblicazioni/add"
      element={<PrivateRoute element={AddPublicazioni} />}
    />

    {/* Archivio Storico */}
    <Route path="/archivio" element={<PrivateRoute element={Archivio} />} />
    <Route
      path="/archivio/add"
      element={<PrivateRoute element={AddArchivio} />}
    />
    <Route
      path="/archivio/view"
      element={<PrivateRoute element={EditArchivio} />}
    />
    <Route
      path="/archivio/edit"
      element={<PrivateRoute element={EditArchivio} />}
    />

    {/* Movimentazione */}
    <Route
      path="/movimentazione"
      element={<PrivateRoute element={Movimentazione} />}
    />
    <Route
      path="/movimentazione/add"
      element={<PrivateRoute element={AddMovimentazione} />}
    />
    <Route
      path="/movimentazione/view"
      element={<PrivateRoute element={EditMovimentazione} />}
    />
    <Route
      path="/movimentazione/edit"
      element={<PrivateRoute element={EditMovimentazione} />}
    />

    {/* Admin Routes */}
    <Route
      path="/users"
      element={<PrivateRoute element={UsersDetailsTable} />}
    />
    <Route
      path="/roles"
      element={<PrivateRoute element={RolesDetailsTable} />}
    />
    <Route
      path="/groups"
      element={<PrivateRoute element={GroupsDetailsTable} />}
    />
    <Route
      path="/manualeutente"
      element={<PrivateRoute element={UserManual} />}
    />
  </Routes>
);

export default AppRoutes;
