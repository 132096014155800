// ThreeJSContainer.js
import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { toast } from "react-toastify";
import { CrossIcon } from "../../assets/icons";

const ThreeJSContainer = ({
  objUrl,
  mtlUrl,
  isExpanded,
  onToggleSize,
  handleDeleteImage,
  imageIdToDelete,
  index,
}) => {
  const containerRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const sceneRef = useRef(null);
  const [isExpand, setIsExpanded] = useState(false);

  useEffect(() => {
    const init = async () => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const camera = new THREE.PerspectiveCamera(
        70,
        container.clientWidth / container.clientHeight,
        0.01,
        1000
      );
      camera.position.set(0, 0, 5);
      cameraRef.current = camera;

      const scene = new THREE.Scene();
      sceneRef.current = scene;
      scene.background = new THREE.Color(0xffffff);

      // Lights
      const ambientLight = new THREE.AmbientLight(0x404040, 20);
      scene.add(ambientLight);

      const pointLight1 = new THREE.PointLight(0xffffff, 19);
      pointLight1.position.set(10, 10, 10);
      scene.add(pointLight1);

      const pointLight2 = new THREE.PointLight(0xffffff, 19);
      pointLight2.position.set(-10, -10, -10);
      scene.add(pointLight2);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 19);
      directionalLight.position.set(0, 10, 10);
      scene.add(directionalLight);

      // Loaders
      const mtlLoader = new MTLLoader();
      const objLoader = new OBJLoader();

      if (mtlUrl) {
        await new Promise((resolve, reject) => {
          mtlLoader.load(
            mtlUrl,
            (materials) => {
              materials.preload();
              objLoader.setMaterials(materials);
              resolve();
            },
            undefined,
            (error) => {
              toast.error("An error occurred while loading MTL:", error);
              reject(error);
            }
          );
        });
      }

      await new Promise((resolve, reject) => {
        objLoader.load(
          objUrl,
          (object) => {
            const box = new THREE.Box3().setFromObject(object);
            const center = box.getCenter(new THREE.Vector3());
            const size = box.getSize(new THREE.Vector3());

            if (
              Number.isNaN(center.x) ||
              Number.isNaN(center.y) ||
              Number.isNaN(center.z)
            ) {
              console.warn(
                `Object at ${objUrl} has invalid dimensions. Skipping rendering.`
              );
              resolve(); // Resolve promise even if invalid
              return;
            }

            object.position.sub(center);
            const maxAxis = Math.max(size.x, size.y, size.z);
            object.scale.setScalar(5 / maxAxis);
            object.position.y = -2;
            scene.add(object);

            // Ensure materials are correctly applied
            object.traverse((child) => {
              if (child.isMesh) {
                child.material.needsUpdate = true;
                child.material.side = THREE.DoubleSide;
                if (!child.material.color) {
                  child.material.color = new THREE.Color(0x888888); // Default color if none specified
                }
              }
            });

            resolve();
          },
          (xhr) => console.log((xhr.loaded / xhr.total) * 100 + "%"),
          undefined,
          (error) => {
            toast.error("An error occurred while loading OBJ:", error);
            reject(error);
          }
        );
      });

      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(container.clientWidth, container.clientHeight);
      rendererRef.current = renderer;
      container.appendChild(renderer.domElement);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.25;
      controls.enableZoom = false;

      const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        renderer.render(scene, camera);
      };
      animate();
    };

    init();

    return () => {
      // Clean up resources when the component is unmounted
      if (rendererRef.current) {
        rendererRef.current.dispose();
      }
      if (containerRef.current) {
        while (containerRef.current.firstChild) {
          containerRef.current.removeChild(containerRef.current.firstChild);
        }
      }
    };
  }, [objUrl, mtlUrl, isExpand]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.width = isExpand ? "500px" : "120px";
      containerRef.current.style.height = isExpand ? "500px" : "120px";
    }
  }, [isExpand]);
  const handleToggleSize = () => {
    setIsExpanded((prev) => !prev);
  };
  
  return (
    <div style={{ position: "relative" }}>
      <div ref={containerRef} style={{ width: "100%", height: "100%" }}></div>
      <button
        onClick={handleToggleSize}
        style={{
          position: "absolute",
          top: -10,
          left: -10,
          cursor: "pointer",
        }}
      >
        {isExpand ? "Shrink" : "Expand"}
      </button>
      <div
        className="icon"
        style={{
          position: "absolute",
          top: -10,
          right: -10,
          cursor: "pointer",
        }}
        onClick={() => handleDeleteImage(index)}
      ><CrossIcon/></div>
    </div>
  );
};

export default ThreeJSContainer;
