import { useRef } from "react";
import "./optionCard.scss";
import useOnOutsideClick from "../../hooks/useOutsideClick";
import { MinusIcon } from "../../assets/icons/minus";
import { CrossIcon } from "../../assets/icons/cross";
import { RightArrowIcon } from "../../assets/icons/rightArrow";

export const OptionCard = ({
  children,
  className = "",
  outsideClickHandler = () => {},
  onCrossClick = () => {},
  onMinimizeClick = () => {},
  onArrowClick = () => {},
  isArrowVisible = false,
  title = "",
  childrenClassName = "",
  showMinimizeOption = false,
}) => {
  const optionCardRed = useRef();

  useOnOutsideClick(optionCardRed, outsideClickHandler);

  return (
    <div
      className={`option-card-main-container ${className}`}
      ref={optionCardRed}
    >
      <div className="option-card-sub-container">
        <div className="option-card-header-wrapper">
          <div className="option-card-main-header">{title}</div>
          <div className="option-card-icon-wrapper">
            {showMinimizeOption && (
              <span className="option-card-icon" onClick={onMinimizeClick}>
                <MinusIcon />
              </span>
            )}
            <span className="option-card-icon" onClick={onCrossClick}>
              <CrossIcon />
            </span>
            {isArrowVisible && (
              <span className="option-card-icon" onClick={onArrowClick}>
                <RightArrowIcon />
              </span>
            )}
          </div>
        </div>
        <div className={`option-card-children-wrapper ${childrenClassName}`}>
          {children}
        </div>
      </div>
    </div>
  );
};
