import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { getDeposito } from "../../../api";
import "./Posizione.scss";

const Posizione = ({ posizione, onChange }) => {
    

  return (
    <div className="posizione-block-container">
            
    </div>
  );
};

export default Posizione;
