import React, { useState, useEffect } from 'react';
import '../../pages/UserManual/UserManual.scss';

const TableOfContents = ({
  manual,
  currentChapter,
  currentSubchapter,
  setCurrentChapter,
  setCurrentSubchapter,
}) => {
  return (
    <div className="table-of-contents">
      {/*<h1 className="manual-header">Guida Utente</h1> */}
      <ul className="chapter-navigation">
        {manual.map((chapter) => (
          chapter.visible === true && <li key={chapter.id}>
            <button
              onClick={() => {
                setCurrentChapter(chapter.id);
                setCurrentSubchapter(chapter.subchapters ? chapter.subchapters[0].id : null);
              }}
              className="chapter-button"
            >
              {chapter.icon && React.createElement(chapter.icon, {fillColor: chapter.id === currentChapter ? "#333" : "#999"})}
              <p className={chapter.id === currentChapter ? "chapter-title-marked" : "chapter-title"} >{chapter.title}</p>
            </button>
            {chapter.id === currentChapter && chapter.subchapters && (
              <ul className="subchapter-navigation">
                {chapter.subchapters.map((subchapter) => (
                 subchapter.visible === true &&  <li key={subchapter.id}>
                    <button
                      onClick={() => {
                        setCurrentChapter(chapter.id)
                        setCurrentSubchapter(subchapter.id)
                      }}
                      className="subchapter-button"
                    >
                      <p className={subchapter.id === currentSubchapter ? "subchapter-title-marked" : "subchapter-title"} >{subchapter.title}</p>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  )
};

export default TableOfContents;