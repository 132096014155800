import React, { useState } from "react";
import { ExclamationTriangleIcon, AddIcon } from "../../../../assets/icons";
import FormField from "../../../FormField/FormField";
import GroupsFormField from "../../../GroupsFormField";
import Raccolta from "../../Raccolta/Raccolta";
import cassettaStructure from "../../../../constants/dataStructure/cassetta.json";
import BlockHeader from "../../../Common/BlockHeader/BlockHeader";
import CustomTooltip from "../../../CustomTooltip";
import AddDepositoModal from "../../Deposito/AddDepositoModal/AddDepositoModal";
import Posizione from "../../Posizione/Posizione";

export default function CassetteRecordDetailForm({
  formData,
  raccolteData,
  handleAddRaccolta,
  handleDeleteRaccolta,
  handleFormDataChange,
  handleRaccoltaChange,
  disabled,
  issues,
  showIssues,
}) {
  const [isAddModalShow, setIsAddModalShow] = useState(false);

  return (
    <>
      <main className="cassette-main-container">
        <div className="content-wrapper">
          <div className="issue-container">
            {showIssues &&
              issues.map((issue, index) => {
                return (
                  <div key={index} className="issue">
                    <p className="issue-icon">
                      <ExclamationTriangleIcon />
                    </p>
                    {issue}
                  </div>
                );
              })}
          </div>
          <div className="input-fields-column">
            <div className="left-column" style={{ width: "100%" }}>
              <div className="form-block">
                <p className="section-title"> GENERALITÀ</p>
                <FormField
                  fieldStructure={cassettaStructure["id"]}
                  value={formData["id"]}
                  onChange={(newValue) => handleFormDataChange("id", newValue)}
                  disabled={false}
                />
                <GroupsFormField
                  groups={formData["groups"]}
                  onChange={(newValue) =>
                    handleFormDataChange("groups", newValue)
                  }
                  edit={true}
                />
                <FormField
                  fieldStructure={cassettaStructure["frontalino"]}
                  value={formData["frontalino"]}
                  onChange={(newValue) =>
                    handleFormDataChange("frontalino", newValue)
                  }
                  disabled={false}
                />
              </div>
              <div className="form-block">
                <BlockHeader title={"Posizione"}>
                  <CustomTooltip text={"Aggiungi Posizione"}>
                    <button
                      className="repertori-button"
                      onClick={() => {
                        setIsAddModalShow(true);
                      }}
                    >
                      <AddIcon fillColor="#e15615" />
                    </button>
                  </CustomTooltip>
                </BlockHeader>
                <Posizione />
              </div>
              <div className="form-block">
                <BlockHeader title={"Posizione"}>
                  <CustomTooltip text={"Aggiungi Posizione"}>
                    <button
                      className="repertori-button"
                      onClick={() => {
                        setIsAddModalShow(true);
                      }}
                    >
                      <AddIcon fillColor="#e15615" />
                    </button>
                  </CustomTooltip>
                </BlockHeader>
                <FormField
                  fieldStructure={cassettaStructure["deposito"]}
                  value={formData["deposito"]}
                  onChange={(newValue) =>
                    handleFormDataChange("deposito", newValue)
                  }
                  disabled={false}
                />
                <FormField
                  fieldStructure={cassettaStructure["piano"]}
                  value={formData["piano"]}
                  onChange={(newValue) =>
                    handleFormDataChange("piano", newValue)
                  }
                  disabled={false}
                />
                <FormField
                  fieldStructure={cassettaStructure["scaffale"]}
                  value={formData["scaffale"]}
                  onChange={(newValue) =>
                    handleFormDataChange("scaffale", newValue)
                  }
                  disabled={false}
                />
                <FormField
                  fieldStructure={cassettaStructure["ripiano"]}
                  value={formData["ripiano"]}
                  onChange={(newValue) =>
                    handleFormDataChange("ripiano", newValue)
                  }
                  disabled={false}
                />
              </div>
            </div>
            <div className="right-column" style={{ width: "100%" }}>
              <div className="form-block">
                <div className="form-block-header">
                  <p className="form-block-header-title"> Raccolte</p>
                  <button
                    className="form-block-button"
                    onClick={handleAddRaccolta}
                  >
                    <AddIcon fillColor="white" />
                    <span>Aggiungi raccolta</span>
                  </button>
                </div>
                {raccolteData.map((raccolta, index) => {
                  return (
                    <Raccolta
                      raccolta={raccolta}
                      handleFieldChange={(key, newValue) =>
                        handleRaccoltaChange(index, key, newValue)
                      }
                      handleDeleteRaccolta={() =>
                        handleDeleteRaccolta(raccolta)
                      }
                      edit={true}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <AddDepositoModal show={isAddModalShow} setShow={setIsAddModalShow} />
      </main>
    </>
  );
}
