import React, { useRef, useState, useEffect } from "react";
import "./SelectInput.scss";
import useOnOutsideClick from "../../hooks/useOutsideClick";
import { DropdownIcon } from "../../assets/icons/dropdown";
import { CheckedIcon } from "../../assets/icons/checked";
import { CheckedboxIcon } from "../../assets/icons/checkedbox";
import { useAppContext } from "../Context/AppContext";
export const Select = ({
  isDisabled,
  className,
  options = [],
  defaultSelected = {},
  onSelect = (option) => {},
  label,
  placeHolder = "Select an option",
  style = {},
  selectedOptionClassName = "",
  optionsClassName = "",
  icon = <DropdownIcon />,
  labelClassName,
  placeHolderClassName = "",
  overlap = false,
  multiSelect = false,
  Open = false,
}) => {
  const {
    singleSelect, // TO BE 
    setSingleSelect,
    multiSelectValue,
    setMultiSelectValue,
    selectCassette,
    setSelectCassette,
    selectReperti,
    setSelectReperti,
    selectPubblicazioni,
    setSelectPubblicazioni,
    multiSelectValueOption, // In case we want Option values for multiselected / groups
    setMultiSelectValueOption
  } = useAppContext();




  const [selectedValue, setSelectedValue] = useState(defaultSelected);
  const [multiSelectedValue, setMultiSelectedValue] = useState({});
  const [multiSelectedOptionValue, setMultiSelectedOptionValue] = useState({});
  const [isSelectAll, setSelectAll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [multiSelectedRepeti, setMultiSelectedRepeti] = useState({});
  const [multiSelectedCassette, setMultiSelectedCassette] = useState({});
  const [multiSelectedPubblicazioni, setMultiSelectedPubblicazioni] = useState(
    {}
  );


  
  // Roles option
  useEffect(() => {
    // const storedSelectedValue = localStorage.getItem("selectedValue");
    if (singleSelect && Open === false) {
      try {
        const parsedValue = JSON.parse(singleSelect);
        if (parsedValue?.id?.includes("role")) {
          setSelectedValue(parsedValue);
        }
      } catch (error) {
        console.error("Failed to parse selectedValue from localStorage", error);
      }
    }
  }, [Open]);

  useEffect(() => {
    if (
      selectedValue &&
      selectedValue?.value &&
      selectedValue?.id?.includes("role")
    ) {
      // localStorage.setItem("selectedValue", JSON.stringify(selectedValue));
      setSingleSelect(selectedValue);
    }
  }, [selectedValue]);

  //  Group option
  // Save multi-selected values for different categories
  useEffect(() => {
    if (
      multiSelect &&
      (selectedValue?.id?.includes("group") ||
        selectedValue?.id?.includes("member-0") ||
        selectedValue?.id?.includes("group-0") ||
        selectedValue?.id?.includes("member"))
    ) {
      // localStorage.setItem(
      //   "multiSelectedValues",
      //   JSON.stringify(multiSelectedValue)
      // );
      setMultiSelectValue(multiSelectedValue) // In case we want true or false values
      // setMultiSelectValueOption(multiSelectedOptionValue) // In case we want option values
    }
    if (multiSelect) {
      if (
        selectedValue?.id?.includes("repeti") 
      ) {
        // localStorage.setItem(
        //   "multiSelectedRepeti",
        //   JSON.stringify(multiSelectedRepeti)
          
        // );
        setSelectReperti(multiSelectedRepeti)
      } else if (
        selectedValue?.id?.includes("cassette")
      ) {
        // localStorage.setItem(
        //   "multiSelectedCassette",
        //   JSON.stringify(multiSelectedCassette)
        // );
        setSelectCassette(multiSelectedCassette);
        
        
      } else if (
        selectedValue?.id?.includes("pubblicazioni") 
      ) {
        // localStorage.setItem(
        //   "multiSelectedPubblicazioni",
        //   JSON.stringify(multiSelectedPubblicazioni)
        // );
        setSelectPubblicazioni(multiSelectedPubblicazioni)
      }
    }
  }, [
    multiSelectedRepeti,
    multiSelectedCassette,
    multiSelectedPubblicazioni,
    multiSelect,
    selectedValue?.id,
  ]);



  useEffect(() => {
    // const storedMultiSelectedRepeti = localStorage.getItem(
    //   "multiSelectedRepeti"
    // );
    // const storedMultiSelectedCassette = localStorage.getItem(
    //   "multiSelectedCassette"
    // );
    // const storedMultiSelectedPubblicazioni = localStorage.getItem(
    //   "multiSelectedPubblicazioni"
    // );
    // const storedMultiSelectedValue = localStorage.getItem(
    //   "multiSelectedValues"
    // );
    if (multiSelectValue && Open === false) {
      try {
        const parsedValue = JSON.parse(multiSelectValue);
        if (parsedValue) {
          setMultiSelectedValue(parsedValue);
        }
      } catch (error) {
        console.error(
          "Failed to parse multiSelectedValue from localStorage",
          error
        );
      }
    }
    if (selectReperti && Open === false) {
      try {
        const parsedValue = JSON.parse(selectReperti);
        setMultiSelectedRepeti(parsedValue || {});
        console.log(parsedValue, "parsedValueRepeti");
      } catch (error) {
        console.error(
          "Failed to parse multiSelectedRepeti from localStorage",
          error
        );
      }
    }

    if (selectCassette && Open === false) {
      try {
        const parsedValue = JSON.parse(selectCassette);
        setMultiSelectedCassette(parsedValue || {});
      } catch (error) {
        console.error(
          "Failed to parse multiSelectedCassette from localStorage",
          error
        );
      }
    }

    if (selectPubblicazioni && Open === false) {
      try {
        const parsedValue = JSON.parse(selectPubblicazioni);
        setMultiSelectedPubblicazioni(parsedValue || {});
      } catch (error) {
        console.error(
          "Failed to parse multiSelectedPubblicazioni from localStorage",
          error
        );
      }
    }
  }, [Open]);



  const multiSelectedValueLength = Object.values(multiSelectedValue).filter(
    (val) => val
  ).length;
  const multiSelectedLength = isSelectAll
    ? multiSelectedValueLength - 1
    : multiSelectedValueLength;
  const multiSelectPlaceHolder =
    multiSelectedLength > 0 ? `${multiSelectedLength} Selected` : "";
   
  const handleSelect = (option) => {
    if (multiSelect) {
      const { value, selectAll = false } = option || {};
      let newMultiSelectedValues = {};
      setSelectAll(selectAll);
      if (selectAll) {
        options.forEach(
          ({ value: checkValue }) =>
            (newMultiSelectedValues[checkValue] =
              !multiSelectedValue[checkValue])
        );
        setMultiSelectValueOption(options.map(({ value }) => value));
      } else {
        newMultiSelectedValues = {
          ...multiSelectedValue,
          [value]: !multiSelectedValue[value],
        };
      }
      setMultiSelectValueOption(prevData => {
       
        const updatedData = prevData.includes(value)
          ? prevData.filter(item => item !== value) 
          : [...prevData, value]; 
        return updatedData;
      });
      if (option.id.includes("repeti")) {
        setMultiSelectedRepeti(newMultiSelectedValues);
      } else if (option.id.includes("cassette")) {
        setMultiSelectedCassette(newMultiSelectedValues);
      } else if (option.id.includes("pubblicazioni")) {
        setMultiSelectedPubblicazioni(newMultiSelectedValues);
      }
      
      setMultiSelectedValue(newMultiSelectedValues);
      
      setSelectedValue(option);
      onSelect(option);
    } else {
      setSelectedValue(option);
      setIsOpen(false);
      onSelect(option);
    }
  };

  useOnOutsideClick(dropdownRef, () => setIsOpen(false));

  return (
    <div
      ref={dropdownRef}
      style={style}
      className={`select-main-container ${className}`}
    >
      {label && (
        <label className={`select-label-text ${labelClassName}`}>{label}</label>
      )}
      <div
        className={`select-box-wrapper ${isDisabled && "select-disabled"}`}
        onClick={() => {
          !isDisabled && !multiSelect && setIsOpen(!isOpen);
        }}
      >
        <div
          className={`selected-value ${placeHolderClassName}`}
          onClick={() => {
            !isDisabled && multiSelect && setIsOpen(!isOpen);
          }}
        >
          <div>
            {multiSelect
              ? multiSelectPlaceHolder || placeHolder
              : selectedValue?.value || placeHolder}
          </div>
          {icon && (
            <span
              className={`select-drop-down-icon ${
                isOpen && "select-rotate-drop-down-icon"
              }`}
            >
              {icon}
            </span>
          )}
        </div>
        {isOpen && (
          <div
            className={`options-container ${
              overlap && "overlapped"
            } ${optionsClassName}`}
          >
            {options.map(({ value, id, uniqueId, selectAll = false }) => {
              // const selected = multiSelect
              //   ? multiSelectedValue[value]
              //   : value === selectedValue?.value;
              let selected;

              // Determine the selected state based on the category
              if (multiSelect && id.includes("repeti")) {
                selected = multiSelectedRepeti[value];
              } else if (multiSelect && id.includes("cassette")) {
                selected = multiSelectedCassette[value];
              } else if (multiSelect && id.includes("pubblicazioni")) {
                selected = multiSelectedPubblicazioni[value];
              } else {
                selected = multiSelect
                  ? multiSelectedValue[value]
                  : value === selectedValue?.value;
              }

              return (
                <div
                  key={value}
                  onClick={() =>
                    handleSelect({ value, id, uniqueId, selectAll })
                  }
                  className={`list-child ${
                    selected &&
                    `selected-value-in-list ${selectedOptionClassName}`
                  }`}
                >
                  <span>{value}</span>
                  {!multiSelect && selected && <CheckedIcon />}
                  {multiSelect && <CheckedboxIcon isChecked={selected} />}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
