import { useState, useEffect, useRef } from "react";
import { DropdownIcon } from "../../assets/icons/dropdown";

const Dropdown = ({ dropdownTexts, defaultSelected = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDropdownText, setSelectedDropdownText] = useState(
    defaultSelected || dropdownTexts[0]?.label
  );
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`cassette-container ${isOpen ? "open" : ""}`}
      onClick={toggleDropdown}
    >
      <div className="cassette-text">{selectedDropdownText}</div>
      <DropdownIcon
        className={`drop-down-icon ${isOpen && "rotate-drop-down-icon"}`}
      />
      <div className={`dropdown ${isOpen ? "open" : ""}`}>
        {dropdownTexts?.map((option, index) => (
          <div
            key={index}
            className="dropdown-item"
            onClick={() => {
              setSelectedDropdownText(option.label);
              // pass content for the data tabl;e
              option.onClick();
            }} // Call handleNavigation function with the route as parameter
          >
            {option?.label}
          </div>
        ))}
      </div>
      <style jsx>{`
        .cassette-container {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 12px 20px;
          border-radius: 6px;
          border: 1px solid rgba(221, 221, 221, 1);
          background-color: #eee;
          font-size: 18px;
          color: #e15615;
          font-weight: 400;
          white-space: nowrap;
          letter-spacing: 0.36px;
          cursor: pointer;
          justify-content: space-between;
        }

        .cassette-text {
          font-family: Gilroy-SemiBold, -apple-system, Roboto, Helvetica,
            sans-serif;
        }

        .cassette-icon {
          width: 24px;
          height: auto;
        }

        .dropdown {
          position: absolute;
          top: calc(100% + 5px);
          right: 0;
          background-color: #fff;
          border: 1px solid #ccc;
          border-radius: 4px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          padding: 10px;
          display: none;
          z-index: 10;
        }

        .dropdown.open {
          display: block;
        }

        .dropdown-item {
          padding: 5px 10px;
          cursor: pointer;
        }
        .drop-down-icon {
          transform: rotate(180deg);
          transition: 0.3s;
        }
        .rotate-drop-down-icon {
          transform: rotate(0deg);
        }
      `}</style>
    </div>
  );
};

export default Dropdown;
