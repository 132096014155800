import React, { useEffect, useState, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getRoles, deleteRole } from "../../api";
import DataTableSubHeader from "../../Components/DataTableSubHeader/DataTableSubHeader";
import useTransformData from "../../hooks/useTransformData";
import {
  RightArrowIcon,
  AddIcon,
  EditIcon,
  CrossIcon,
} from "../../assets/icons";
import Dropdown from "../../Components/Dropdown/Dropdown";
import ItemsTable from "../../Components/ItemsTable/ItemsTable";
import AddRoleModal from "../../Components/RolesDetailsTable/AddRoleModal/AddRoleModal";
import EditRoleModal from "../../Components/RolesDetailsTable/EditRoleModal/EditRoleModal";
import DeleteModal from "../../Components/Common/DeleteModal/DeleteModal";
import "./RolesDetailsTable.scss";

const selectedHeaders = [
  {
    key: "name",
    label: "Nome ruolo",
    width: "300px",
    sortable: true,
  },
  {
    key: "color",
    label: "Colore",
    width: "300px",
    sortable: false,
  },
  {
    key: "authorities",
    label: "Autorità",
    width: "300px",
    sortable: false,
  },
  { key: "actions", label: "", width: "300px", sortable: false },
];

const config = {
  fields: [
    {
      name: "id",
      type: "text",
    },
    {
      name: "name",
      type: "text",
    },
    {
      name: "color",
      type: "color",
    },
    {
      name: "authorities",
      type: "list",
    },
  ],
};

const RolesDetailsTable = () => {
  const navigate = useNavigate();

  const [isEditing, setEditing] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});

  const transformedData = useTransformData(
    formData,
    isEditing,
    setIsEditModalOpen,
    setIsDeleteModalOpen,
    setSelectedRole,
    config
  );

  const breadcrumbsData = useMemo(
    () => [
      {
        id: 1,
        label: `Administration ${localStorage.getItem("username")}`,
        iconUrl: true,
      },
      {
        id: 2,
        label: "ROLES",
        iconUrl: "",
      },
    ],
    []
  );

  const handleCancelEdit = useCallback(() => setEditing(false), []);
  const handleSaveEdit = useCallback(() => setEditing(false), []);
  const handleOpenModal = useCallback(() => setModalOpen(true), []);

  const fetchRoles = useCallback(async () => {
    try {
      const response = await getRoles();
      console.log(response);
      setFormData(response.roles);
    } catch (error) {
      toast.error("Error fetching roles");
    }
  }, []);

  const handleDeleteRole = useCallback(async (id) => {
    try {
      await deleteRole(id);
      toast.success("Role deleted successfully");
      fetchRoles();
    } catch (error) {
      toast.error("Error deleting role");
    } finally {
      setIsDeleteModalOpen(false);
    }
  }, [fetchRoles]);

  const editingOptions = useMemo(
    () => (
      <div className="editing-tools-button-wrapper">
        <button
          className="action-button cancel-button"
          onClick={handleCancelEdit}
        >
          <CrossIcon />
          <span>Cancel</span>
        </button>
        <button className="action-button save-button" onClick={handleSaveEdit}>
          <AddIcon fillColor="white" />
          <span>Save</span>
        </button>
      </div>
    ),
    [handleCancelEdit, handleSaveEdit]
  );

  const subHeaderRightNavOptions = useMemo(
    () => ({
      icons: isEditing
        ? [
            {
              key: "back",
              icon: <RightArrowIcon className="reverted-edit-icon" />,
              onClick: handleCancelEdit,
              label: "Back",
            },
            {
              key: "add",
              icon: <AddIcon onClick={handleOpenModal} />,
              onClick: handleOpenModal,
              label: "Add",
            },
          ].filter(Boolean)
        : [
            {
              key: "edit",
              icon: <EditIcon fillColor="#E15615" height="28" width="28" />,
              onClick: () => setEditing(!isEditing),
              label: "Edit",
            },
          ],
      components: isEditing
        ? [editingOptions]
        : [
            <Dropdown
              key="dropdown"
              dropdownTexts={[
                {
                  label: "UTENTI",
                  onClick: () => navigate("/users"),
                },
                {
                  label: "GRUPPI",
                  onClick: () => navigate("/groups"),
                },
                {
                  label: "RUOLI",
                  onClick: () => navigate("/roles"),
                },
              ]}
              defaultSelected="RUOLI"
            />,
          ],
    }),
    [isEditing, editingOptions, handleCancelEdit, handleOpenModal, navigate]
  );

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  return (
    <div className="roles-container">
      <main className="roles-main-content">
        <DataTableSubHeader
          title="ROLES"
          breadcrumbsData={breadcrumbsData}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setMenuActive(!isMenuActive)}
        />
        <ItemsTable
          data={transformedData}
          headers={selectedHeaders}
          isEditing={isEditing}
        />
        {modalOpen && (
          <AddRoleModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            fetchRoles={fetchRoles}
          />
        )}
        {isEditModalOpen && (
          <EditRoleModal
            show={isEditModalOpen}
            roleData={selectedRole}
            fetchRoles={fetchRoles}
            handleClose={() => setIsEditModalOpen(false)}
          />
        )}
        {isDeleteModalOpen && (
          <DeleteModal
            show={isDeleteModalOpen}
            item={selectedRole}
            itemType="Role"
            handleClose={() => setIsDeleteModalOpen(false)}
            handleDelete={handleDeleteRole}
          />
        )}
      </main>
    </div>
  );
};

export default RolesDetailsTable;
