import React from "react";
import {
  AddIcon,
  CloudIcon,
  DownloadIcon,
  FilterIcon,
} from "../../assets/icons/index.js";
import jsonToXlsx from "../../helpers/formTools/transformations/jsonToXlsx";
import DataDropdown from "../DataDropdown";

export const subHeaderRightNavOptions = (userCanCreate, data, navigate) => ({
  icons: [
    false && {
      icon: <CloudIcon />,
      onClick: () => console.log("Upload"),
      label: "Upload",
    },
    userCanCreate && {
      icon: <AddIcon />,
      label: "Add",
      onClick: () => navigate("/pubblicazioni/add"),
    },
    {
      icon: <FilterIcon />,
      onClick: () => console.log("Filter"),
      label: "Filter",
    },
    {
      icon: <DownloadIcon />,
      label: "Download",
      onClick: () => {
        const jsonArr = data.map(({ actions, ...rest }) => rest);
        jsonToXlsx(jsonArr, "pubblicazioni.xlsx");
      },
    },
  ],
  components: [<DataDropdown defaultSelected="PUBBLICAZIONI" />],
});
