import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomModal from "../../Common/CustomModal";
import Select from "react-select";
import "./style.scss";
import { useAppContext } from "../../Context/AppContext";
import { getComuneLayers } from "../../../api";
import { ExclamationTriangleIcon } from "../../../assets/icons";
import validateProvenienza from "../../../helpers/formTools/validations/provenienza/validateProvenienza";

const ProvenienzaAddModal = ({
  show,
  provenienze,
  handleClose,
  handleAddProvenienza,
}) => {
  const navigate = useNavigate();
  const { comuni } = useAppContext();
  const [selectedComune, setSelectedComune] = useState(null);
  const [layers, setLayers] = useState([]);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [issues, setIssues] = useState([]);

  const layersOptions = useMemo(
    () =>
      layers
        .filter((l) => l.tipo !== "comune")
        .map((item) => ({
          value: item.id,
          label: item.tipo,
          chiave: item.chiave,
        })),
    [layers]
  );

  const isDisableSelect = useMemo(() => provenienze.length > 0, [provenienze]);

  const listComuneLayers = useCallback(
    async (comune) => {
      try {
        const data = await getComuneLayers(comune.value);
        setLayers(data.layers);
        return data.layers;
      } catch (error) {
        if (error.response.status === 401) {
          navigate("/");
          console.error(`Authorization error listing layers`);
        } else {
          toast.error(
            error.response && error.response.detail
              ? error.response.detail
              : `Unknown error listing layers`
          );
          console.error(`Authorization error slisting layers`, error);
        }
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (selectedComune) listComuneLayers(selectedComune);
  }, [selectedComune, listComuneLayers]);

  useEffect(() => {
    if (provenienze.length === 0) return;
    const comune = comuni.find((c) => c?.id === provenienze[0].comuneId);
    setSelectedComune({ value: comune?.id, label: comune.nome });
  }, [provenienze, comuni]);

  // useEffect(() => {
  //   if (layers.length === 0 || provenienze.length === 0) return;
  //   const layer = layers.find((l) => l.tipo === provenienze[0].tipo);
  //   setSelectedLayer({
  //     value: layer?.id,
  //     label: layer.tipo,
  //     chiave: layer.chiave,
  //   });
  // }, [layers, provenienze]);

  /* ALL HANDLERS */

  const onSelectedComune = (comune) => {
    setSelectedComune(comune);
  };

  const onSelectedLayer = (layer) => {
    setSelectedLayer(layer);
  };

  const onSelectedValue = (e) => {
    setSelectedValue(e.target.value);
  };
  /* TRANSFORMATIONS */

  const comuniOptions = comuni.map((item) => ({
    value: `${item.id}`,
    label: `${item.nome}`,
  }));

  const onAddProvenienza = (e) => {
    e.preventDefault();
    const provenienza = {
      comuneId: selectedComune.value,
      tipo: selectedLayer.label,
      chiave: selectedLayer.chiave,
      valore: selectedValue,
    };
    const issues = validateProvenienza(provenienza);
    if (issues.length > 0) {
      setIssues(issues);
      return;
    }
    handleAddProvenienza(provenienza);
  };

  return (
    <CustomModal
      title={"AGGIUNGI PROVENIENZA"}
      isVisible={show}
      onClose={handleClose}
    >
      <div className="issue-container">
        {issues.length > 0 &&
          issues.map((issue, index) => {
            return (
              <div key={index} className="issue">
                <p className="issue-icon">
                  <ExclamationTriangleIcon />{" "}
                </p>
                {issue}
              </div>
            );
          })}
      </div>
      <form onSubmit={onAddProvenienza}>
        <div className="add-user-modal-body">
          <div className="input-wrapper">
            <label htmlFor="comune" className="input-label">
              Comune:
            </label>
            <Select
              options={comuniOptions}
              required
              value={selectedComune}
              isDisabled={isDisableSelect}
              placeholder="Select an option"
              onChange={onSelectedComune}
            ></Select>
          </div>
          <div className="input-wrapper">
            <label htmlFor="layer" className="input-label">
              Layer:
            </label>
            <Select
              options={layersOptions}
              required
              value={selectedLayer}
              placeholder="Select an option"
              onChange={onSelectedLayer}
            ></Select>
          </div>
          <div className="input-wrapper">
            <label htmlFor="value" className="input-label">
              {selectedLayer ? selectedLayer.chiave : "..."}
            </label>
            <input
              type="string"
              name="value"
              value={selectedValue}
              id="value"
              placeholder=""
              required
              onChange={onSelectedValue}
            />
          </div>
        </div>
        <footer className="modal-footer">
          <button type="submit" className="modal-footer-button">
            Submit
          </button>
        </footer>
      </form>
    </CustomModal>
  );
};

export default ProvenienzaAddModal;
