import { UploadFileIcons } from "../../assets/icons/addFile";
import "./FileUploadSection.scss";

const FileUploadSection = ({ handleUpload, acceptType }) => {
  return (
    <div className="file-upload-section">
      <UploadFileIcons />
      <div className="file-upload-content">
        <div className="">
          <label className="file-upload-browse-button" htmlFor="cassette-image">
            Browse Files
          </label>
          <input
            id="cassette-image"
            hidden
            type="file"
            onChange={handleUpload}
            accept={acceptType}
            multiple
          />
        </div>
        <p className="file-upload-info">
          Allowed file formats are {acceptType}. <br />
          maximum upload size: 3 mb
        </p>
      </div>
    </div>
  );
};

export default FileUploadSection;
