import React, { useEffect, useMemo, useState, useCallback } from "react";
import "./Archivio.scss";
import { useNavigate } from "react-router-dom";
import DataTableSubHeader from "../../Components/DataTableSubHeader/DataTableSubHeader.jsx";
import { EyeIcon, DeleteIcon } from "../../assets/icons/index.js";
import { useAppContext } from "../../Components/Context/AppContext.jsx";
import { getArchivio, deleteArchivio } from "../../api/archivio.js";
import { SubHeaderMobileOption } from "../../Components/SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { subHeaderRightNavOptions } from "../../Components/Archivio/SubHeaderNavOption/SubHeaderNavOption.jsx";
import datastructure from "../../constants/dataStructure/archivio.json";
import ItemsTable from "../../Components/ItemsTable/ItemsTable.jsx";
import { toast } from "react-toastify";
import userHasPermission from "../../helpers/formTools/userHasPermission/index.js";
import ReactModal from "../../Components/ReactModal/ReactModal.jsx";
import FilterModal from "../../Components/FilterModal/FilterModal.jsx";
import Spinner from "../../Components/ProgressBar/Spinner.js";

const breadcrumbsData = [
  { id: 1, label: "Dati Archeologici", iconUrl: true },
  { id: 2, label: "Archivio storico", iconUtl: true },
  { id: 3, label: "Elenco fascicoli", iconUrl: "" },
];

const headers = [
  { key: "actions", label: "", width: "100px", sortable: false },
  { key: "id", label: "ID fascicolo", width: "180px", sortable: false },
  {
    key: "fascicolo",
    label: "Numero fascicolo",
    width: "100px",
    sortable: false,
  },
  { key: "posizione", label: "Posizione", width: "100px", sortable: false },
  { key: "protocollo", label: "Protocollo", width: "100px", sortable: false },
  { key: "sito", label: "Sito", width: "100px", sortable: false },
  { key: "comune", label: "Comune", width: "100px", sortable: false },
  { key: "categoria", label: "Categoria", width: "100px", sortable: false },
  { key: "classe", label: "Classe", width: "100px", sortable: false },
];

const filterValues = [
  { label: "ID fascicolo", key: "id" },
  { label: "Numero fascicolo", key: "fascicolo" },
  { label: "posizione", key: "posizione" },
  { label: "protocollo", key: "protocollo" },
  { label: "sito", key: "sito" },
  { label: "comune", key: "comune" },
  { label: "categoria", key: "categoria" },
  { label: "classe", key: "classe" },
];

function Archivio() {
  const { setArchivioFilter, archivioFilter, setArchivioId, userProfile } =
    useAppContext();
  const navigate = useNavigate();
  const [isMenuActive, setMenuActive] = useState(false);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [sortDirection, setSortDirection] = useState("asc");
  const [recordFilterModalOpen, setRecordFilterModalOpen] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const userCanCreate = userHasPermission({
    userProfile,
    resource: "ARCHIVIOSTORICO",
    permission: "CREATE",
  });

  const toggleMenuActive = useCallback(
    () => setMenuActive(!isMenuActive),
    [isMenuActive]
  );

  const getArchivioData = useCallback(async () => {
    setIsLoading(true);
    try {
      let filterData = {
        page: currentPage,
        pageSize: recordsPerPage,
      };
      if (Array.isArray(archivioFilter) && archivioFilter.length > 0) {
        filterData = [...archivioFilter].reduce((acc, item) => {
          if (item && item.key && item.value) {
            acc[item.key] = item.value;
          }
          return acc;
        }, {});
      }

      const response = await getArchivio(filterData);
      console.log("--->>>>", response);

      setData(response?.results);
      setTotalPages(response?.totalPages);
      setTotalRecords(response?.totalElements);
    } catch (err) {
      toast.error("Error fetching data");
    } finally {
      setIsLoading(false);
    }
  }, [archivioFilter, currentPage, recordsPerPage]);

  const removeArchivio = useCallback(async (id) => {
    try {
      await deleteArchivio(id);
      console.log("remove-archivio-->>", id);
      toast.success("Data deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 20);
    } catch (error) {
      toast.error("Error deleting data");
    }
  }, []);

  const handlePageChange = useCallback(
    (newPage) => {
      setCurrentPage(newPage);
    },
    [setCurrentPage]
  );

  const handleRecordsPerPageChange = useCallback(
    (newRecordsPerPage) => {
      setRecordsPerPage(newRecordsPerPage);
      setCurrentPage(1); // Reset to first page when records per page changes
    },
    [setRecordsPerPage, setCurrentPage]
  );

  const handleCallBackSort = useCallback(
    async (key) => {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
      setArchivioFilter([{ key: "sort", value: `${key},${sortDirection}` }]);
    },
    [setSortDirection, setArchivioFilter, sortDirection]
  );

  const handleFilterIconClick = useCallback(() => {
    setRecordFilterModalOpen(true);
  }, []);

  const closeRecordFilterModal = useCallback(() => {
    setRecordFilterModalOpen(false);
  }, []);

  const handleCallBackFilter = useCallback(
    (data) => {
      const filter = data.map((item) => {
        return {
          key: filterValues.find((f) => f.label === item.label).key,
          value: item.value,
        };
      });
      setArchivioFilter(filter);
      return true;
    },
    [setArchivioFilter]
  );

  useEffect(() => {
    getArchivioData();
  }, [getArchivioData]);

  const convertedData = useMemo(() => {
    if (!Array.isArray(data)) return [];

    let mappedData = []; // Initialize the array to store the converted data

    data.forEach((item) => {
      const result = item;

      let jsonObject = { id: { value: result.id }, actions: { value: [] } }; // Initialize the row object

      // Convert row attributes in the right format base on a key and a value to be displayed
      for (const key in result) {
        if (result.hasOwnProperty(key)) {
          let value;
          if (datastructure[key] && datastructure[key].type === "object") {
            value = result[key][datastructure[key].objectName];
          } else {
            value =
              result[key] !== undefined && result[key] !== null
                ? result[key].toString()
                : "";
          }
          jsonObject[key] = {
            value,
          };
        }
      }

      // Identifies the ID of the record
      const Id = jsonObject.id.value.replace(/^"|"$/g, "");

      // Add the actions to the row
      jsonObject.actions = {
        value: [],
        component: (
          <>
            <div
              onClick={() => {
                navigate("/archivio/view");
                setArchivioId(Id);
              }}
              className="row-icon"
              style={{ cursor: "pointer" }}
            >
              <EyeIcon />
            </div>
            {userHasPermission({
              userProfile,
              resource: "ARCHIVIOSTORICO",
              resourceGroups: result.groups,
              permission: "DELETE",
            }) && (
              <div
                onClick={() => removeArchivio(Id)}
                style={{ cursor: "pointer" }}
              >
                <DeleteIcon />
              </div>
            )}
          </>
        ),
      };
      mappedData.push(jsonObject);
    });

    return mappedData;
  }, [data, setArchivioId, userProfile, removeArchivio, navigate]);

  console.log("convertedData", convertedData);
  return (
    <>
      <div className="container">
        <main className="record-main-content">
          <DataTableSubHeader
            title={"ARCHIVIO STORICO"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={subHeaderRightNavOptions(
              userCanCreate,
              convertedData,
              handleFilterIconClick,
              navigate
            )}
            isMenuActive={isMenuActive}
            setMenuActive={toggleMenuActive}
          />
          {isLoading ? (
            <Spinner />
          ) : (
            <ItemsTable
              headers={headers}
              data={convertedData}
              filterValues={filterValues}
              totalRecords={totalRecords}
              currentPage={currentPage}
              recordsPerPage={recordsPerPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
              callBackSort={handleCallBackSort}
            />
          )}
        </main>
        <ReactModal
          isOpen={recordFilterModalOpen}
          onClose={closeRecordFilterModal}
        >
          <FilterModal
            closeModal={closeRecordFilterModal}
            filterValues={filterValues}
            setFilterValue={setFilterValue}
            callBackFilter={handleCallBackFilter}
            onReset={() => {}}
          />
        </ReactModal>
        {isMenuActive && (
          <SubHeaderMobileOption
            components={
              subHeaderRightNavOptions(userCanCreate, navigate)?.components
            }
            icons={subHeaderRightNavOptions(userCanCreate, navigate)?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </div>
    </>
  );
}

export default Archivio;
