import React from "react";
import "../GroupsFormField.scss";
import { AddIcon }  from "../../../assets/icons";
import CustomTooltip from "../../CustomTooltip";


  const GroupsHeader = ({
    edit,
    onOpenModal,
    title
  }) => {
    return (
      <div className="groups-header">
        <p className="groups-header-title">{title}</p>
        {edit && (
          <CustomTooltip text="Aggiungi gruppo">
            <button className="groups-button" onClick={onOpenModal}>
              <AddIcon fillColor="#e15615" />
            </button>
          </CustomTooltip>
        )}
      </div>
    );
  };

export default GroupsHeader;
