import Select from "react-select";
import { AddIcon, DeleteIcon } from "../../../../assets/icons";
import CustomTooltip from "../../../CustomTooltip";
import { movimentazioneReferentiJson } from "./constant";
import "./ReferentiSection.scss";

const RefrentiSection = ({
  referenti,
  handleAddReferente,
  handleUpdateReferente,
  handleDeleteReferente,
}) => {
  console.log("referenti", referenti);
  const handleSelectChange = (selectedOption, id) => {
    handleUpdateReferente({ tipo: selectedOption }, id);
  };

  const handleInputChange = (e, id) => {
    handleUpdateReferente({ valore: e.target.value }, id);
  };
  return (
    <div className="referenti-selection-container">
      <div className="referenti-section-header">
        <label className="input-select-label" htmlFor="referenti-text">
          Referenti
        </label>
        <CustomTooltip text={"Aggiungi Referento"}>
          <button className="referenti-add-button" onClick={handleAddReferente}>
            <AddIcon fillColor="#e15615" width={24} height={24} />
          </button>
        </CustomTooltip>
      </div>
      {referenti.length > 0 &&
        referenti.map((referente, index) => (
          <div className="referento-selection-wrapper" key={index}>
            <Select
              options={movimentazioneReferentiJson}
              value={referente?.tipo}
              placeholder="Select Referenti"
              onChange={(selectedOption) =>
                handleSelectChange(selectedOption, referente?.id)
              }
            />
            <input
              type="text"
              id="referenti-text"
              name="referenti"
              onChange={(e) => handleInputChange(e, referente?.id)}
            />
            <button
              className="referento-remove-btn"
              onClick={() => handleDeleteReferente(referente?.id)}
            >
              <DeleteIcon />
            </button>
          </div>
        ))}
    </div>
  );
};

export default RefrentiSection;
