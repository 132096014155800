import "./ResetPasswordForm.scss";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { InputComponent } from "../../Components/InputComponent/InputComponent";
import { InfoCircle, LeftArrowIcon } from "../../assets/icons";
import { CheckedIcon } from "../../assets/icons/checked";
import { CheckedLargeIcon } from "../../assets/icons/checkedLargeIcon";

function ResetPasswordForm() {
  const [isPasswordStrength, setIsPasswordStrength] = React.useState(false);
  const [isPasswordReset, setIsPasswordReset] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const handleOnNewPassword = (e) => {
    setPassword(e?.target?.value);
    if (e?.target?.value?.length < 8) {
      setIsPasswordStrength(true);
    } else {
      setIsPasswordStrength(false);
    }
  };
  const handleOnConfirmPassword = (e) => {};
  const handleOnResetPassword = () => {
    setIsPasswordReset(true);
  };

  return (
    <>
      <form
        className="reset-password-container"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="header-container">
          <h1 className="reset-password-title">Reset Password</h1>
          <p className="reset-password-description">
            Enter your details to access your account.
          </p>
        </div>
        <InputComponent
          label="Enter New Password"
          placeholder="Enter password"
          type={"password"}
          htmlFor={"new-password"}
          onChange={handleOnNewPassword}
          labelClassName={"input-label"}
          value={password}
        />
        <InputComponent
          label="Confirm New Password"
          placeholder="Enter password"
          type={"password"}
          htmlFor={"confirm-password"}
          onChange={handleOnConfirmPassword}
          labelClassName={"input-label"}
        />

        {isPasswordStrength && (
          <div className="password-strength">
            <InfoCircle fillColor="#E15615" />
            <div className="strength-text">Password Strength: Weak</div>
          </div>
        )}
        <button
          onClick={handleOnResetPassword}
          className="reset-password-button"
        >
          <span className="button-text">Reset Password</span>
          <CheckedIcon fillColor="#fff" />
        </button>
        {isPasswordReset && (
          <div className="password-status-card">
            <div className="status-card-header">Password Reset Status</div>
            <div className="status-card-body">
              <div className="content-container">
                <CheckedLargeIcon />
                <div className="text-container">
                  Password Reset Successful!
                  <br /> You can now login with new password.
                </div>
                <button
                  className="staus-login-button"
                  onClick={() => {
                    navigate("/signin");
                  }}
                >
                  <span className="button-text">Login</span>
                  <LeftArrowIcon />
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </>
  );
}

export default ResetPasswordForm;
