import { MenuIcon } from "../../assets/icons";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import SubHeaderRightNavOptions from "../SubHeaderRightNavOptions/SubHeaderRightNavOptions";
import "./DataTableSubHeader.scss";

function DataTableSubHeader({
  title,
  breadcrumbsData,
  subHeaderRightNavOptions,
  isMenuActive = false,
  setMenuActive = ()=>{},
}) {
  return (
    <>
      <div className="cassette-record-details">
        <div className="record-info">
          <h2 className="title">{title}</h2>
          <div className="details">
            <Breadcrumbs breadcrumbsData={breadcrumbsData} />
          </div>
        </div>
        <MenuIcon className="cassette-mobile-menu-icon" isActive={isMenuActive} onClick={setMenuActive}/>
        <SubHeaderRightNavOptions
          icons={subHeaderRightNavOptions?.icons}
          components={subHeaderRightNavOptions?.components}
        />
      </div>
    </>
  );
}

export default DataTableSubHeader;
