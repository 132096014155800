import "./BackgroundMap.scss";
import { OptionCard } from "../optionCard/optionCard";
import { WorldIcon } from "../../assets/icons/world";
export const BackgroundMap = ({ onClose, onSelect = ()=>{}, backgroundMaps }) => {


  // const handleOnCloudFileUpload = (e) => {};
  return (
    <OptionCard
      className="select-background-container"
      onCrossClick={onClose}
      // onMinimizeClick={handleOnMinimizeClick}
      title="Seleziona sfondo"
    >
      <div className="select-background-option-body-container">
        { Array.isArray(backgroundMaps) && backgroundMaps.map((background, index) => ( background.active === true  &&
          <div className="select-background-item-container" onClick={() => {
            onSelect(background);
          }} key={index}>
            <label htmlFor="select-background-item" className="select-background-item">
              <WorldIcon />
              <span className="select-background-text">{background.label}</span>
            </label>
          </div>
        ))}
      </div>
    </OptionCard>
  );
};
