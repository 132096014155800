import "./LoginSection.scss";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { LeftArrowIcon } from "../../assets/icons";
import panoramica from "../../assets/images/panoramica.jpg";

function LoginSection() {
  const navigate = useNavigate();
  localStorage.removeItem("cbcValue");
  localStorage.removeItem("AREA_SCAVO");
  return (
    <section className="login-page-container ">
      <p className="login-title">PARCO ARCHEOLOGICO SIBARI</p>
      <p className="login-description">
        SISTEMA INFORMATIVO PER LA GESTIONE DEI DATI ARCHEOLOGICI
      </p>
      <div className="intro-image-frame1">
        <div className="intro-image-frame2">
          <img src={panoramica} alt="panoramica" className="intro-image" />
        </div>
      </div>

      <button onClick={() => navigate("/signin")} className="login-button">
        <span className="login-text">Login</span>
        <LeftArrowIcon />
      </button>
    </section>
  );
}

export default LoginSection;
