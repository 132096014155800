import { ExclamationTriangleIcon, CopyIcon } from "../../../assets/icons";
import FormField from "../../FormField/FormField";
import AutoriTable from "../AutoriTable/AutoriTable";
import UploadBiblografia from "../UploadBiblografia/UploadBiblografia";

const PubblicazioniDetailForm = ({
  showIssues,
  issues,
  disabled = false,
  formData,
  bibliografiaStructure,
  copyToClipboard,
  handleFormDataChange,
  create = false
}) => {
  return (
    <div className="content-wrapper">
      <div className="issue-container">
        {showIssues &&
          issues.map((issue, index) => {
            return (
              <div key={index} className="issue">
                <p className="issue-icon">
                  <ExclamationTriangleIcon />{" "}
                </p>
                {issue}
              </div>
            );
          })}
      </div>
      <div className="input-fields-column pubblicazioni-input-fields">
        <div className="left-column" style={{ width: "100%" }}>
          <div className="form-block">
            <p className="section-title">GENERALITÀ</p>
            <div className="reperto-id-header">
              <p className="reperto-id-text">Identificativo</p>
              <p
                className="copy-text"
                onClick={() => copyToClipboard(formData?.id)}
              >
                {formData?.id}
                <CopyIcon />
              </p>
            </div>
            <FormField
              fieldStructure={bibliografiaStructure["titolo"]}
              value={formData["titolo"]}
              onChange={(newValue) => handleFormDataChange("titolo", newValue)}
              disabled={disabled}
            />
            { create !== true && (
            <FormField
              fieldStructure={bibliografiaStructure["abbreviazione"]}
              value={formData["abbreviazione"]}
              onChange={(newValue) =>
                handleFormDataChange("abbreviazione", newValue)
              }
              disabled={true}
            />
            )}
            <FormField
              fieldStructure={bibliografiaStructure["annoPubblicazione"]}
              value={formData["annoPubblicazione"]}
              onChange={(newValue) =>
                handleFormDataChange("annoPubblicazione", newValue)
              }
              disabled={disabled}
            />
            <FormField
              fieldStructure={bibliografiaStructure["pagine"]}
              value={formData["pagine"]}
              onChange={(newValue) => handleFormDataChange("pagine", newValue)}
              disabled={disabled}
            />
            <FormField
              fieldStructure={bibliografiaStructure["tipoDocumento"]}
              value={formData["tipoDocumento"]}
              onChange={(newValue) =>
                handleFormDataChange("tipoDocumento", newValue)
              }
              disabled={disabled}
            />
            <FormField
              fieldStructure={bibliografiaStructure["note"]}
              value={formData["note"]}
              onChange={(newValue) => handleFormDataChange("note", newValue)}
              disabled={disabled}
            />
            <FormField
              fieldStructure={bibliografiaStructure["idDocumento"]}
              value={formData["idDocumento"]}
              onChange={(newValue) =>
                handleFormDataChange("idDocumento", newValue)
              }
              disabled={disabled}
            />
            <FormField
              fieldStructure={bibliografiaStructure["editore"]}
              value={formData["editore"]}
              onChange={(newValue) => handleFormDataChange("editore", newValue)}
              disabled={disabled}
            />
            <FormField
              fieldStructure={bibliografiaStructure["volume"]}
              value={formData["volume"]}
              onChange={(newValue) => handleFormDataChange("volume", newValue)}
              disabled={disabled}
            />
            <FormField
              fieldStructure={bibliografiaStructure["paginaInizio"]}
              value={formData["paginaInizio"]}
              onChange={(newValue) =>
                handleFormDataChange("paginaInizio", newValue)
              }
              disabled={disabled}
            />
            <FormField
              fieldStructure={bibliografiaStructure["paginaFinale"]}
              value={formData["paginaFinale"]}
              onChange={(newValue) =>
                handleFormDataChange("paginaFinale", newValue)
              }
              disabled={disabled}
            />
            <FormField
              fieldStructure={bibliografiaStructure["fonte"]}
              value={formData["fonte"]}
              onChange={(newValue) => handleFormDataChange("fonte", newValue)}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="right-column" style={{ width: "100%" }}>
          <div className="form-block">
            <div className="form-block-header">
              <p className="form-block-header-title">Autori</p>
            </div>
            <AutoriTable
              autori={formData?.autori}
              edit={!disabled}
              onChange={(newValue) => handleFormDataChange("autori", newValue)}
            />
          </div>
          <UploadBiblografia
            idPdfBibliografia={formData?.allegati}
            disabled={disabled}
            onChange={(newValue) => handleFormDataChange("allegati", newValue)}
          />
        </div>
      </div>
    </div>
  );
};

export default PubblicazioniDetailForm;
