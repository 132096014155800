import React, { useState, useCallback, useRef } from "react";
import "./RepertiRecordFileUploadModal.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getHeaders,
  getBatch,
  uploadFile,
  importRepertiRecords,
} from "./apiService";
import { tipoJson } from "./constant";
import ProcessSection from "./ProcessSection/ProcessSection";
import SpinnerLoading from "../../SpinnerLoading/SpinnerLoading";
import { CrossIcon, MinusIcon } from "../../../assets/icons";
import MatchSection from "./MatchSecion/MatchSection";
import { saveAs } from "file-saver";
import axios from "axios";
import config from "../../../config";
import { useAppContext } from "../../Context/AppContext";

function RepertiRecordFileUploadModal({
  closeModal,
  title = "UPLOAD RECORDS",
  handleFileUploadCallBack,
}) {
  const { userProfile } = useAppContext();
  const [fileId, setFileId] = useState(null);
  const [batchId, setBatchId] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [layoutData, setLayoutData] = useState({});
  const [batchData, setBatchData] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [origins, setOrigins] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [inventoryCodes, setInventoryCodes] = useState([]);
  const [group, setGroup] = useState(null);

  const intervalIdRef = useRef(null);

  const handleAssocChange = useCallback((data) => {
    setLayoutData(data);
  }, []);

  const handleTipoChange = useCallback(
    (type, selectedOption, selectedId) => {
      if (type === "origin") {
        const originData = [...origins];

        const selectedOrigin = originData.find(
          (origin) => origin.id === selectedId
        );
        selectedOrigin.selectedTipo = selectedOption;
        selectedOrigin.tipo = selectedOption?.value;

        const originIndex = originData.findIndex(
          (origin) => origin.id === selectedId
        );
        originData[originIndex] = selectedOrigin;

        setOrigins(originData);
      } else {
        const inventoryData = [...inventoryCodes];

        const selectedInventory = inventoryData.find(
          (inventory) => inventory.id === selectedId
        );
        selectedInventory.selectedTipo = selectedOption;
        selectedInventory.tipo = selectedOption?.value;

        const inventoryIndex = inventoryData.findIndex(
          (inventory) => inventory.id === selectedId
        );
        inventoryData[inventoryIndex] = selectedInventory;

        setInventoryCodes(inventoryData);
      }
    },
    [origins, inventoryCodes]
  );

  const handleSelectChange = useCallback(
    (type, selectedOption, label, selectedId) => {
      if (type === "origin") {
        const originData = [...origins];

        const selectedOrigin = originData.find(
          (origin) => origin.id === selectedId
        );

        if (label === "comune") {
          selectedOrigin.selectedComune = selectedOption;
          selectedOrigin.comune = { index: selectedOption?.index };
        } else if (label === "valore") {
          selectedOrigin.selectedValore = selectedOption;
          selectedOrigin.valore = { index: selectedOption?.index };
        }

        const originIndex = originData.findIndex(
          (origin) => origin.id === selectedId
        );
        originData[originIndex] = selectedOrigin;

        setOrigins(originData);
      } else {
        const inventoryData = [...inventoryCodes];

        const selectedInventory = inventoryData.find(
          (inventory) => inventory.id === selectedId
        );

        selectedInventory.selectedCodice = selectedOption;
        selectedInventory.codice = { index: selectedOption?.index };

        const inventoryIndex = inventoryData.findIndex(
          (inventory) => inventory.id === selectedId
        );
        inventoryData[inventoryIndex] = selectedInventory;

        setInventoryCodes(inventoryData);
      }
    },
    [origins, inventoryCodes]
  );

  const handleAnnotationChange = useCallback(
    (e, annotationId) => {
      const annotationData = [...annotations];
      const selectedAnnotation = annotationData.find(
        (annotation) => annotation.id === annotationId
      );
      selectedAnnotation[e.target.name] = e.target.value;
      const annotationIndex = annotationData.findIndex(
        (annotation) => annotation.id === annotationId
      );
      annotationData[annotationIndex] = selectedAnnotation;
      setAnnotations(annotationData);
    },
    [annotations]
  );

  const handleInputChange = useCallback(
    (e, type, label, selectedId) => {
      if (type === "origin") {
        const originData = [...origins];

        const selectedOrigin = originData.find(
          (origin) => origin.id === selectedId
        );

        if (label === "comune") {
          selectedOrigin.comune = { value: e.target.value };
        } else if (label === "valore") {
          selectedOrigin.valore = { value: e.target.value };
        }

        const originIndex = originData.findIndex(
          (origin) => origin.id === selectedId
        );
        originData[originIndex] = selectedOrigin;

        setOrigins(originData);
      } else {
        const inventoryData = [...inventoryCodes];

        const selectedInventory = inventoryData.find(
          (inventory) => inventory.id === selectedId
        );

        selectedInventory.codice = { value: e.target.value };

        const inventoryIndex = inventoryData.findIndex(
          (inventory) => inventory.id === selectedId
        );
        inventoryData[inventoryIndex] = selectedInventory;

        setInventoryCodes(inventoryData);
      }
    },
    [origins, inventoryCodes]
  );

  const handleKeyChange = useCallback(
    (e, originId) => {
      const originData = [...origins];

      const selectedOrigin = originData.find(
        (origin) => origin.id === originId
      );
      selectedOrigin.chiave = e.target.value;

      const originIndex = originData.findIndex(
        (origin) => origin.id === originId
      );
      originData[originIndex] = selectedOrigin;

      setOrigins(originData);
    },
    [origins]
  );
  const handleGroupChange = useCallback((selectedOption) => {
    console.log("group--selectedOption--->>", selectedOption);
    setGroup(selectedOption);
  }, []);

  const handleAddAnnotation = useCallback(() => {
    setAnnotations((prevAnnotations) => [
      ...prevAnnotations,
      {
        index: "",
        value: "",
        id: new Date().getTime(),
      },
    ]);
  }, []);

  const handleAddOrigin = useCallback(() => {
    setOrigins((prevOrigins) => [
      ...prevOrigins,
      {
        comune: null,
        selectedComune: null,
        tipo: "",
        selectedTipo: null,
        chiave: "",
        valore: "",
        selectedValore: null,
        id: new Date().getTime(),
      },
    ]);
  }, []);

  const handleDeleteAnnotation = useCallback(
    (index) => {
      const annotationData = [...annotations];
      const newData = annotationData.filter(
        (annotation) => annotation.id !== index
      );
      setAnnotations(newData);
    },
    [annotations]
  );

  const handleDeleteOrigin = useCallback(
    (index) => {
      const originData = [...origins];
      const newData = originData.filter((origin) => origin.id !== index);
      setOrigins(newData);
    },
    [origins]
  );

  const handleAddInventoryCode = useCallback(() => {
    setInventoryCodes([
      ...inventoryCodes,
      {
        tipo: "",
        selectedTipo: null,
        codice: null,
        selectedCodice: null,
        id: new Date().getTime(),
      },
    ]);
  }, [inventoryCodes]);

  const handleDeleteInventory = useCallback(
    (inventoryId) => {
      const inventoryData = inventoryCodes.filter(
        (inventory) => inventory.id !== inventoryId
      );

      setInventoryCodes(inventoryData);
    },
    [inventoryCodes]
  );

  const handleProcess = useCallback(async () => {
    const matchData = {
      fileId,
      layout: {
        ...layoutData,
        groups: { value: group?.name },
        annotazioni: annotations?.map((annotation) => ({
          index: annotation.index,
          value: annotation.value,
        })),
        provenienze: origins?.map((origin) => ({
          tipo: origin.tipo,
          chiave: origin.chiave,
          valore: origin.valore,
          comune: origin.comune,
        })),
        codiciInventario: inventoryCodes?.map((inventoryCode) => ({
          tipo: inventoryCode.tipo,
          codice: inventoryCode.codice,
        })),
      },
    };
    try {
      const batchId = await importRepertiRecords(matchData);
      setBatchId(batchId);
      setIsProcessing(true);
      intervalIdRef.current = setInterval(async () => {
        const batchResponse = await getBatch(batchId);
        setBatchData(batchResponse?.job);
        if (batchResponse?.job?.status === "finished") {
          clearInterval(intervalIdRef.current);
          setIsProcessing(false);
          // handleFileUploadCallBack();
          // closeModal();
          return;
        }
      }, 5000);

      return () => {
        clearInterval(intervalIdRef.current);
        setIsProcessing(false);
      };
    } catch (error) {
      toast.error(`Error: ${error.data.details}`);
    }
    console.log("match--data-->>>", matchData);
  }, [fileId, origins, annotations, group?.name, inventoryCodes, layoutData]);

  const handleReportDownload = async () => {
    try {
      const res = await axios.get(
        `${config.archeoBaseUrl}/api/private/v3/file/download/${batchData?.outputFileId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          responseType: "arraybuffer",
        }
      );

      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, `${batchData?.name}`);
    } catch (err) {
      console.log("down-err--->>", err);
      toast.error(`Error: ${err.message}`);
    }
  };

  const handleCSVXlsUpload = useCallback(async (ev) => {
    const files = ev.target.files;
    if (files.length) {
      setIsFileUploading(true);
      Array.from(files).forEach(async (file) => {
        const reader = new FileReader();
        reader.onload = async function () {
          try {
            const uploadedFileId = await uploadFile(file);
            setFileId(uploadedFileId);

            if (uploadedFileId) {
              const headersData = await getHeaders(uploadedFileId);
              setHeaders(headersData);
            }
          } catch (error) {
            toast.error(`Error: ${error.message}`);
          } finally {
            setIsFileUploading(false);
          }
        };

        if (
          file.name.endsWith(".csv") ||
          file.name.endsWith(".xls") ||
          file.name.endsWith(".xlsx")
        ) {
          reader.readAsBinaryString(file);
        } else {
          console.error("Unsupported file format.");
          setIsFileUploading(false);
        }
      });
    }
  }, []);

  return (
    <>
      <section className="modal-container">
        {/* modal header */}
        <header className="modal-header">
          <div className="modal-header-content">
            <h1 className="modal-title">{title}</h1>
            <div className="modal-icons">
              <div className="modal-icon" onClick={closeModal}>
                <MinusIcon />
              </div>
              <div className="modal-icon" onClick={closeModal}>
                <CrossIcon />
              </div>
            </div>
          </div>
        </header>

        {batchId ? (
          <ProcessSection batchData={batchData} isProcessing={isProcessing} />
        ) : (
          <MatchSection
            groups={userProfile?.groups}
            group={group}
            headers={headers}
            inventoryCodes={inventoryCodes}
            handleTipoChange={handleTipoChange}
            handleKeyChange={handleKeyChange}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            handleAssocChange={handleAssocChange}
            handleCSVXlsUpload={handleCSVXlsUpload}
            handleAddOrigin={handleAddOrigin}
            handleAddAnnotation={handleAddAnnotation}
            handleDeleteOrigin={handleDeleteOrigin}
            handleDeleteAnnotation={handleDeleteAnnotation}
            handleDeleteInventory={handleDeleteInventory}
            handleAddInventoryCode={handleAddInventoryCode}
            handleAnnotationChange={handleAnnotationChange}
            handleGroupChange={handleGroupChange}
            origins={origins}
            annotations={annotations}
            tipoJson={tipoJson}
          />
        )}

        {/* footer */}
        {headers.length ? (
          <footer className="modal-footer">
            {batchId ? (
              <button
                className={`modal-footer-button ${
                  isProcessing ? "disable" : ""
                }`}
                onClick={handleReportDownload}
              >
                Download
              </button>
            ) : (
              <button className="modal-footer-button" onClick={handleProcess}>
                Process
              </button>
            )}
          </footer>
        ) : null}
        <SpinnerLoading isLoading={isFileUploading} />
      </section>
    </>
  );
}

export default RepertiRecordFileUploadModal;
