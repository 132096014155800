import { DeleteIcon } from "../../../../assets/icons";
import "./AnnotationSection.scss";

const AnnotationSection = ({
  annotation,
  handleAnnotationChange,
  handleDeleteAnnotation,
}) => {
  const onKeyChange = (e) => {
    handleAnnotationChange(e, annotation?.id);
  };

  const onDeleteOrigin = () => {
    handleDeleteAnnotation(annotation?.id);
  };

  return (
    <div className="annotation-section-wrapper">
      <div className="annotation-delete-wrapper">
        <button onClick={onDeleteOrigin}>
          <DeleteIcon />
        </button>
      </div>
      <div className="annotation-inputs-wrapper">
        <div className="annotation-input-wrapper">
          <label className="chiave-label" htmlFor="chiave">
            Chiave
          </label>
          <input
            id="chiave"
            name="index"
            type="text"
            value={annotation?.index}
            onChange={onKeyChange}
          />
        </div>
        <div className="annotation-input-wrapper">
          <label className="chiave-label" htmlFor="chiave">
            Valore
          </label>
          <input
            id="valore"
            name="value"
            type="text"
            value={annotation?.value}
            onChange={onKeyChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnotationSection;
