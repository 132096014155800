import React from "react";
export const DownIcons = ({ fillColor = "#E15615" }) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9336 7.31641H9.74194H5.06695C4.26695 7.31641 3.86695 8.28307 4.43361 8.84974L8.75028 13.1664C9.44194 13.8581 10.5669 13.8581 11.2586 13.1664L12.9003 11.5247L15.5753 8.84974C16.1336 8.28307 15.7336 7.31641 14.9336 7.31641Z"
      fill="#E15615"
    />
  </svg>
);
