import httpArcheo from "../helpers/http-archeo";

export const getMuseo = async () => {
  try {
    const response = await httpArcheo.get("/api/private/v3/museo/get", {
      pageSize: 10,
      page: 1,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
