import { useState, useCallback, useEffect } from "react";
import "./MovimentazioneSection.scss";
import BlockHeader from "../../Common/BlockHeader/BlockHeader";
import AddMovimentazioneModal from "./AddMovimentazioneModal/AddMovimentazioneMoal";
import MovimentazioneSectionTable from "./MovimentazioneSectionTable/MovimentazioneSectionTable";
import { AddIcon, InputCircleIcon, OutputCircleIcon } from "../../../assets/icons";
import CustomTooltip from "../../CustomTooltip";
import UscitaEventModal from "./UscitaEventModal/UscitaEventModal";
import { getMovimentazione } from "../../../api";

const MovimentazioneSection = ({ edit, repertiId }) => {
  const [isAddModalShow, setIsAddModalShow] = useState(false);
  const [isUscitaEventModalShow, setIsUscitaEventModalShow] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const fetchMovimentazioneData = useCallback(async () => {
    try {
      const data = await getMovimentazione({
        "reperto.id": repertiId,
        pageSize: rowsPerPage,
        page: page,
      });
      console.log("movimentazione data", data);
    } catch (error) {
      console.error("Error fetching movimentazione data", error);
    }
  }, [repertiId, page, rowsPerPage]);

  useEffect(() => {
    fetchMovimentazioneData();
  }, [fetchMovimentazioneData]);

  return (
    <div>
      <BlockHeader title="MOVIMENTAZIONE">
        {edit && (
          <div className="block-header-buttons">
            <CustomTooltip text={"Aggiungi Movimentazione"}>
              <button
                className="repertori-button"
                onClick={() => {
                  setIsAddModalShow(true);
                }}
              >
                <AddIcon fillColor="#e15615" />
              </button>
            </CustomTooltip>
            <CustomTooltip text={"Entrata Event"}>
              <button
                className="repertori-button"
                onClick={() => {
                  setIsAddModalShow(true);
                }}
              >
                <InputCircleIcon fillColor="#e15615" />
              </button>
            </CustomTooltip>
            <CustomTooltip text={"Uscita Event"}>
              <button
                className="repertori-button"
                onClick={() => {
                  setIsUscitaEventModalShow(true);
                }}
              >
                <OutputCircleIcon fillColor="#e15615" />
              </button>
            </CustomTooltip>
          </div>
        )}
      </BlockHeader>
      <MovimentazioneSectionTable />
      {isAddModalShow && (
        <AddMovimentazioneModal
          show={isAddModalShow}
          repertiId={repertiId}
          disabled={!edit}
          handleClose={() => setIsAddModalShow(false)}
        />
      )}
      {isUscitaEventModalShow && (
        <UscitaEventModal
          isModalOpen={isUscitaEventModalShow}
          closeModal={() => setIsUscitaEventModalShow(false)}
        />
      )}
    </div>
  );
};

export default MovimentazioneSection;
