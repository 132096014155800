import { useState, useCallback, useMemo } from "react";
import CustomModal from "../../../Common/CustomModal";
import FormField from "../../../FormField/FormField";
import { CopyIcon, CalendarIcon } from "../../../../assets/icons";
import movimentazioneStructure from "../../../../constants/dataStructure/movimentazione.json";
import CustomToggleButton from "../../../CustomToggleButton";
import DatePickerModal from "../../../Common/DatePickerModal/DatePickerModal";
import UploadPdfComponent from "../../../Common/UploadPdfComponent/UploadPdfComponent";
import RefrentiSection from "../ReferentiSection/ReferentiSection";
import { ExclamationTriangleIcon } from "../../../../assets/icons";
import { validateMovimentazione } from "../../../../helpers/formTools/validations/movimentazione/validateMovimentazione";
import { newMovimentaznione, uploadFile } from "../../../../api";
import { CloudUploadIcon, CheckedIcon } from "../../../../assets/icons";
import "./AddMovimentazioneModal.scss";
import { toast } from "react-toastify";

const AddMovimentazioneModal = ({ show, disabled, repertiId, handleClose }) => {
  const [formData, setFormData] = useState({
    termineProvisionale: true,
    repertoId: repertiId,
    referenti: [
      {
        tipo: "",
        valore: "",
      },
    ],
  });
  const [isInizioDatePickerShow, setIsInizioDatePickerShow] = useState(false);
  const [isTermineDatePickerShow, setIsTermineDatePickerShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [referentiData, setReferentiData] = useState([
    {
      tipo: {
        label: null,
        value: null,
      },
      valore: null,
      id: new Date().getTime(),
    },
  ]);

  const issues = useMemo(() => {
    const newData = {
      ...formData,
      referenti: referentiData?.map((item) => ({
        tipo: item.tipo.value,
        valore: item.valore,
      })),
    };
    return validateMovimentazione(newData);
  }, [formData, referentiData]);

  const handleFieldChange = useCallback((key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  }, []);

  const handleToggle = useCallback((e, newState) => {
    setFormData((prevData) => ({
      ...prevData,
      termineProvisionale: newState,
    }));
  }, []);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {},
      (err) => {}
    );
  };

  const handleAddReferente = useCallback(() => {
    setReferentiData((prevData) => [
      ...prevData,
      {
        tipo: {
          label: null,
          value: null,
        },
        valore: null,
        id: new Date().getTime(),
      },
    ]);
  }, []);

  const handleDeleteReferente = useCallback((id) => {
    setReferentiData((prevData) => prevData.filter((item) => item.id !== id));
  }, []);

  const handleUpdateReferente = useCallback((updatedData, id) => {
    setReferentiData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, ...updatedData } : item
      )
    );
  }, []);

  const handleAuthUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async function () {
      try {
        const uploadedFileId = await uploadFile(file);
        setFormData((prevData) => ({
          ...prevData,
          autorizzazioneId: uploadedFileId,
        }));
      } catch (error) {
        console.error("Error uploading file.", error);
        toast.error("Error uploading file.");
      }
    };
    console.log(file);
  };

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      const submitData = {
        ...formData,
        allegati: formData.allegati?.map((item) => item.id),
        referenti: referentiData?.map((item) => ({
          tipo: item.tipo.value,
          valore: item.valore,
        })),
      };
      console.log(formData, "-->>>", referentiData);
      await newMovimentaznione(submitData);
      toast.success("Movimentazione added successfully");
    } catch (err) {
      console.error("Error adding movimentazione", err);
      toast.error("Error adding movimentazione");
    } finally {
      setIsLoading(false);
      handleClose();
    }
  }, [formData, referentiData, handleClose]);
  return (
    <CustomModal
      title="Aggiungi Movimentazione"
      isVisible={show}
      onClose={handleClose}
    >
      <div className="add-movimentazione-modal">
        {issues.length
          ? issues.map((issue, index) => {
              return (
                <div key={index} className="issue">
                  <p className="issue-icon">
                    <ExclamationTriangleIcon />
                  </p>
                  {issue}
                </div>
              );
            })
          : null}
        <RepertoIdHeader
          repertiId={repertiId}
          copyToClipboard={copyToClipboard}
        />
        {Object.keys(movimentazioneStructure).map((key) => (
          <FormField
            key={key}
            fieldStructure={movimentazioneStructure[key]}
            value={formData[key]}
            onChange={(newValue) => handleFieldChange(key, newValue)}
            disabled={disabled}
          />
        ))}
        <div className="autorizzazione-wrapper">
          <p>Allega autorizzazione</p>
          {formData?.autorizzazioneId ? (
            <div className="autorizzazione-icon-wrapper">
              <CheckedIcon fillColor="#00ff00" />
            </div>
          ) : (
            <label
              className="autorizzazione-icon-wrapper"
              htmlFor="autorizzazione-file"
            >
              <CloudUploadIcon />
              <input
                id="autorizzazione-file"
                hidden
                type="file"
                onChange={handleAuthUpload}
              />
            </label>
          )}
        </div>
        <DateTimePicker
          label="Inizio"
          date={formData?.inizio}
          onClick={() => setIsInizioDatePickerShow(true)}
        />
        <DateTimePicker
          label="Termine"
          date={formData?.termine}
          onClick={() => setIsTermineDatePickerShow(true)}
        />
        {/* <div className="movimentazione-toggle-wrapper">
          <p>Termine Provisionale</p>
          <CustomToggleButton
            initialState={formData?.termineProvisionale}
            onToggle={handleToggle}
          />
        </div> */}
        <RefrentiSection
          referenti={referentiData}
          handleAddReferente={handleAddReferente}
          handleUpdateReferente={handleUpdateReferente}
          handleDeleteReferente={handleDeleteReferente}
        />
        <UploadPdfComponent
          idPdfBibliografia={formData?.allegati}
          disabled={disabled}
          onChange={(newValue) => handleFieldChange("allegati", newValue)}
        />
      </div>
      <footer className="modal-footer">
        <button
          className="modal-footer-button"
          disabled={issues.length || isLoading}
          onClick={onSubmit}
        >
          Submit
        </button>
      </footer>

      {isInizioDatePickerShow && (
        <DatePickerModal
          show={isInizioDatePickerShow}
          handleSubmit={(selectedDate) => {
            handleFieldChange("inizio", selectedDate);
          }}
          handleClose={() => setIsInizioDatePickerShow(false)}
        />
      )}
      {isTermineDatePickerShow && (
        <DatePickerModal
          show={isTermineDatePickerShow}
          handleSubmit={(selectedDate) => {
            handleFieldChange("termine", selectedDate);
          }}
          handleClose={() => setIsTermineDatePickerShow(false)}
        />
      )}
    </CustomModal>
  );
};

const RepertoIdHeader = ({ repertiId, copyToClipboard }) => (
  <div className="reperto-id-header">
    <p className="reperto-id-text">Reperti ID</p>
    <p className="copy-text" onClick={() => copyToClipboard(repertiId)}>
      {repertiId}
      <CopyIcon />
    </p>
  </div>
);

const DateTimePicker = ({ label, date, onClick }) => (
  <div className="movimentazione-date-time-picker">
    <div className="movimentazione-date-time-picker-header">
      <p>{label}</p>
      <button className="date-time-picker-icon" onClick={onClick}>
        <CalendarIcon />
      </button>
    </div>
    <p>{date?.toLocaleString()}</p>
  </div>
);

export default AddMovimentazioneModal;
