import { useState, useEffect, useMemo, useCallback } from "react";
import { useAppContext } from "../../../Components/Context/AppContext";
import { useNavigate, useLocation } from "react-router-dom";
import { getBibliografiaById, updateBibliografia } from "../../../api";
import { toast } from "react-toastify";
import PubblicazioniDetailForm from "../../../Components/Pubblicazioni/PubblicazioniDetailForm/PubblicazioniDetailForm";
import DataTableSubHeader from "../../../Components/DataTableSubHeader/DataTableSubHeader";
import { AddIcon, CrossIcon, RightArrowIcon, EditIcon } from "../../../assets/icons";
import validatePubblicazioni from "../../../helpers/formTools/validations/pubblicazioni/validatePubblicazioni";
import bibliografiaStructure from "../../../constants/dataStructure/biblografia.json";

const pubblicazioneRecordDetailsBreadcrumbs = [
  {
    id: 1,
    label: "Dati Archeoligici",
    iconUrl: true,
  },
  {
    id: 2,
    label: "PUBBLICAZIONI",
    iconUrl: true,
  },
  { id: 3, label: "Edit PUBBLICAZIONI Record Details", iconUrl: "" },
];

const EditPublicazioni = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { bibliografiaId } = useAppContext();

  const [formData, setFormData] = useState({});
  const [showIissues, setShowIssues] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);

  const disabled = useMemo(
    () => pathname !== "/pubblicazioni/edit",
    [pathname]
  );
  const issues = useMemo(() => validatePubblicazioni(formData), [formData]);

  const subHeaderRightNavOptions = {
    icons: disabled
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => {
              navigate(-1);
            },
            label: "Back",
          },
          {
            icon: <EditIcon fillColor="#E15615" />,
            onClick: () => {
              navigate("/pubblicazioni/edit");
            },
            label: "Edita",
          },
        ]
      : [],
    components: disabled
      ? []
      : [
          <button onClick={() => navigate(-1)} className="cancel-button">
            <CrossIcon />
            <span className="login-text">Cancel</span>
          </button>,
          <button
            onClick={() => {
              navigate(-1);
              handleSave();
            }}
            className="save-button"
          >
            <AddIcon fillColor="#fff" />
            <span className="login-text">Salva</span>
          </button>,
        ],
  };

  const handleSave = useCallback(async () => {
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    navigate(-1);

    try {
      const submitData = {
        ...formData,
        allegati: formData?.allegati?.map((item) => item.id),
      };
      await updateBibliografia(submitData);
      toast.success("Data updated successfully");
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while saving the data");
    }
  }, [formData, issues, navigate]);

  const fetchBibliografiaById = useCallback(async (id) => {
    try {
      const response = await getBibliografiaById(id);
      const fetchedData = {
        ...response?.bibliografiaEntityBean,
        allegati: response?.bibliografiaEntityBean?.allegati?.map((item) => {
          const nameWithoutId = item?.name?.includes(item.id)
            ? item.name.replace(`${item.id}\\`, "")
            : item.name;
          return {
            id: item.id,
            name: nameWithoutId,
          };
        }),
      };

      setFormData(fetchedData);
    } catch (error) {
      console.log("fetch-biblografia-by-id-->>", error);
      toast.error("Data fetch failed!");
    }
  }, []);

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  const handleFormDataChange = (key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  useEffect(() => {
    if (bibliografiaId) {
      fetchBibliografiaById(bibliografiaId);
    }
  }, [bibliografiaId, fetchBibliografiaById]);

  return (
    <div className="container">
      <main className="record-detail-main-content">
        <DataTableSubHeader
          title={"PUBBLICAZIONI Record Details"}
          breadcrumbsData={pubblicazioneRecordDetailsBreadcrumbs}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setMenuActive(!isMenuActive)}
        />
        <PubblicazioniDetailForm
          showIssues={showIissues}
          issues={issues}
          disabled={disabled}
          formData={formData}
          bibliografiaStructure={bibliografiaStructure}
          copyToClipboard={copyToClipboard}
          handleFormDataChange={handleFormDataChange}
        />
      </main>
    </div>
  );
};

export default EditPublicazioni;
