import React from "react";
import "../Provenienze.scss";
import { AddIcon }  from "../../../assets/icons";
import CustomTooltip from "../../CustomTooltip";

  const ProvenienzeHeader = ({
    edit = false,
    onOpenModal,
  }) => {
    return (
      <div className="provenienze-header">
          <p className="provenienze-header-title">Provenienze</p>
        {edit && (
          <CustomTooltip text="Aggiungi provenienza">
            <button className="provenienze-button"  onClick={onOpenModal}>
                <AddIcon fillColor="#e15615" />
            </button>
          </CustomTooltip>
        )}
      </div>
    );
  };

export default ProvenienzeHeader;
