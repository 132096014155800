import React, { useState } from "react";
import CustomModal from "../../CustomModal";
import { AddIcon, CrossIcon } from "../../../assets/icons";

const AddAutoriModal = ({ show, handleClose, handleAdd }) => {
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");

  const handleAddAutori = (e) => {
    e.preventDefault();
    handleAdd({ nome, cognome });
    setNome("");
    setCognome("");
    handleClose();
  };

  return (
    <CustomModal isVisible={show} onClose={handleClose}>
      <form className="class-summary-add-modal" onSubmit={handleAddAutori}>
        <h2 className="class-summary-add-modal-title">AGGIUNGI AUTORE</h2>
        <div className="class-summary-add-modal-content">
          <div className="class-summary-add-modal-input">
            <label htmlFor="nome">Nome</label>
            <input
              type="text"
              id="nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </div>
          <div className="class-summary-add-modal-input">
            <label htmlFor="cognome">Cognome</label>
            <input
              type="text"
              id="cognome"
              value={cognome}
              onChange={(e) => setCognome(e.target.value)}
            />
          </div>
        </div>
        <div className="class-summary-add-modal-btns">
          <button className="autori-cancel-button" onClick={handleClose}>
            <CrossIcon width={24} height={24} />
            <span className="login-text">Cancel</span>
          </button>
          <button className="autori-save-button">
            <AddIcon fillColor="#fff" width={24} height={24} />
            <span className="login-text">Save</span>
          </button>
        </div>
      </form>
    </CustomModal>
  );
};

export default AddAutoriModal;
